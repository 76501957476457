
nav.mashie-drawer {
    height: 100%; /* 100% Full-height */
    width: 400px; /* 0 width - change this with JavaScript */  
    position: fixed; /* Stay in place */
    z-index: 100000; /* Stay on top */
    top: 0; /* Stay at the top */
    right: 0;
    background-color: white; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    //   padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    box-shadow: 4px 0 25px -2px gray;
  } 