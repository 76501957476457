.information-kitchen--info {
      width: 90%;
      margin: -25px 0 15px 0;

    h3 {
        font-weight: bold;
        margin: 0 0 5px 0;
    }

    div {
        padding: 0 10px 10px 15px;

        h4 {

            margin: 0 0 5px 0; 
            font-weight: bold;
        }

        a {
            font-size: 13px;

            &:hover {
                cursor: pointer;
            }

            &:visited {
                color: #95b417;
            }
        }
    }
}
