
input[type="text"].mashie-small {
    width: 6rem;
    height: 2rem;
    margin-left: 2px;
    margin-right: 10px;
    margin-bottom: 4px;

    &:disabled{
        background-color:white;
        border: none;
    }
}

input[type="text"].mashie-search {
    margin-left: 0px;
}
