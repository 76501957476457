@import "../../notification-app.less";

.notification-box {
  cursor: pointer;
  padding: 20px 15px 20px 5px;  

  .note-date {
    justify-content: start;
    place-self: start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-self: center;
  }

  .notification-title { 
    font-size: 1.6em;
    margin: 20px 10px 5px 0px;
  }

  .showMoreToggle {
    color: @blue;
    cursor: pointer;
    margin-top: 10px;
  }

  .readState {
    font-size: 0.8em;
    color: @blue;
  }
  .doneState {
    align-self: center;
    color: @blue;
    justify-content: end;
    place-self: center;
    input {
      border-radius: 2px;
      border: 2px solid @blue;
      margin: 0 0 0 5px;
      
    }
  }

  .notification-text {
    
    height: auto;
    overflow: hidden;   
 
  }

  &:hover {
    background-color: rgb(246, 246, 246);
  }
}
