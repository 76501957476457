@import "../components/mashiecolors.less";

.list-group .scroll-group {
    border-bottom: 1px solid @list-group-border;
    border-radius: @list-group-border-radius;
    max-height: 333px;
    overflow-y: auto;
}

.specification-list {
    list-style: none;
    padding: 0;

    li {
        display: inline-block;
        vertical-align: top;

        select {
            margin: 0 10px 0 0;
        }

        label {

            margin: 0 10px 10px 0;

            strong {
            }

            span {
                margin : 0 10px 0 5px;
                font-size: 1.3rem;
                font-weight: 600;
                //border : 1px solid @mashie-green;
                color : @blue;
                border-radius : 2px;
                padding : 5px;
                //text-decoration : underline;
            }
        }
    }
}
