@import "transfer.less";
@import "hierarchy-delete.less";
@import "hierarchy-copy.less";
@import "add-to-basket.less";


.hierachy-button--copy {
    background: #e9ed97 !important;
}

.hierachy-button--delete {
    background: #e9ed97 !important;
}

.basket-select--wrapper {
    padding-bottom: 30px;
}

.basket-public--checkbox > label {
    display: inline-block;
}

.basket-public--checkbox > label > input {
    position: relative !important;
    top: 2px !important;
}

.basket-toolbar--wrapper {
    padding-top: 15px;
}