@import '../../mashiecolors.less';

#fetchCustomOrderModal {
  overflow: hidden;
  background-color: rgba(155, 155, 155, 0.5);

  .modal-dialog {
    .modal-content {
      display: flex;
      flex-flow: column;
      min-height: 100%;
      .modal-header {
        flex: 1 1 auto;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        .close {
          background-color: @lightblue;
          opacity: 1;
          font-size: 2em;
          border-radius: 2px;
          margin-right: 1em;
        }

        .modal-date {
          align-self: flex-start;
        }
      }

      .modal-body {
        display: flex;
        height: 100%;
        flex: 9 1 auto;
        padding: 20px 20px 0;
        overflow-y: hidden;
        .modal-wrapper {
          height: 100%;
          width: 100%;
          article {
            height: 100%;
            .modal-main {
              display: flex;
              flex-direction: column;
              height: 100%;
              .modal-selections {
                padding-bottom: 10px;
              }
              .modal-results {
                display: flex;
                overflow-y: hidden;
                .results {
                  flex: 1 1 auto;
                  margin-top: 1em;
                  padding-bottom: 3em;
                  &:first-child {
                    flex: 0 0 auto;
                    margin-right: 1em;
                  }
                }
              }
            }
          }
        }
      }

      .modal-footer {
        // flex flow column does not account for item padding or margin, so we need to pull this up to fit into the overall container of whatever
        // the combined padding and or margins that exist between the footer and the body.  As of right now the padding for .moal-body is 20px on
        // top and 60px on the bottom so 80px total, plus a top padding of 20px on the footer so 100px;
        // margin-top: -100px;
        background: white;
        z-index: 89;
        flex: 1 1 auto;
        text-align: left;
        width: 100%;
      }
    }
  }
}
