#menu-publishing-control {
    // Colors
    @primary-color: #1976d2;
    @primary-hover-color: #0056b3;
    @text-color: rgba(0, 0, 0, 0.87);
    @link-color: #007bff;
    @link-hover-color: #0056b3;
    @warning-color: #ff9800;

    // Background colors
    @bg-color-main: #fff;
    @bg-color-disabled: #9393931f;
    @bg-color-table-header: #f5f5f5;
    @bg-color-table-row-even: #f9f9f9;
    @bg-color-table-row-hover: #f1f1f1;
    @bg-color-button-outline-hover: #e3f2fd;

    // Border colors
    @border-color-main: #ccc;
    @border-color-primary: #1976d2;
    @border-color-disabled: rgba(0, 0, 0, 0.12);
    @border-color-table: #ddd;

    // Text colors
    @text-color-disabled: rgba(0, 0, 0, 0.26);

    @checkmark-color-disabled: rgba(0, 0, 0, 0.6);


    &.publication-control {
        max-width: 100%;

        input[type="checkbox"] {
            position: relative;
            margin: 0 5px;
            width: 16px;
            height: 16px;
            appearance: none;
            border: 1px solid @border-color-primary;
            border-radius: 3px;
            outline: none;
            cursor: pointer;
            transition: border-color 0.3s, background-color 0.3s;

            &:disabled {
                border: 1px solid @border-color-disabled;
                cursor: default;
                background-color: @bg-color-disabled;
            }

            &:focus {
                outline: none;
                -webkit-appearance: none;
            }

            &:hover {
                border-color: @text-color;

                &:disabled {
                    border-color: @border-color-disabled;
                }
            }

            &:checked {
                background-color: @primary-color;
                border-color: @border-color-primary;

                &:disabled {
                    background-color: @bg-color-disabled;
                    border-color: @border-color-disabled;

                    &::after {
                        border-color: @checkmark-color-disabled; // Using the new variable
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 3px;
                    left: 5px;
                    width: 4px;
                    height: 8px;
                    border: solid @bg-color-main;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }
            }
        }

        .common {
            display: grid;
            grid-template-columns: 1fr 1fr 3fr;
            gap: 10px;
            margin: 20px 0;
            background-color: @bg-color-main;

            div {
                align-items: center;
            }

            .checkbox-container {
                display: grid;
                align-items: center;
                grid-template-columns: 25px 2fr;
                margin-bottom: 30px;

                label {
                    white-space: nowrap;
                }
            }

            label {
                display: inline-block;
                width: auto;
                font-weight: bold;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            textarea {
                width: 90%;
                padding: 2px;
                border: 1px solid @border-color-main;
                border-radius: 4px;
                box-sizing: border-box;
                height: 60px;

                &:disabled {
                    background-color: @bg-color-disabled;
                    border-color: @border-color-disabled;
                }
            }

            input[type="text"],
            select {
                width: 90%;
                padding: 2px;
                border: 1px solid @border-color-main;
                border-radius: 4px;
                box-sizing: border-box;
                height: 30px;

                &:disabled {
                    background-color: @bg-color-disabled;
                    border-color: @border-color-disabled;
                }
            }
        }

        .info {
            display: flex;
        }

        .info-icon {
            width: 16px;
            height: 16px;
            margin: 10px;
        }

        .extra-info {
            width: 100%;
            padding: 8px;
            border: 1px solid @border-color-main;
            border-radius: 4px;
            box-sizing: border-box;
        }

        .icon-info {
            cursor: pointer;
            color: @link-color;

            &:hover {
                color: @link-hover-color;
            }
        }

        .link {
            color: @link-color;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        .warning-icon {
            color: @warning-color;
            margin-left: 5px;
        }

        .button-container {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
        }

        .info-icon:hover {
            color: @link-hover-color;
        }
    }

    .mui-table {
        width: 100%;
        border-collapse: collapse;
        font-family: Arial, sans-serif;

        .left {
            display: flex;
            text-align: left;
            margin: 0 10px;
            width: 100%;
            align-items: center
        }

        .center {
            .left;
            place-content: center;
        }


        tr {
            padding: 5px;
        }

        input[type="text"],
        select {
            border: 1px solid @border-color-main;
            border-radius: 4px;
            box-sizing: border-box;
            height: 30px;
            width: -moz-available; /* WebKit-based browsers will ignore this. */
            width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
            width: fill-available;

            &:disabled {
                background-color: @bg-color-disabled;
                border-color: @border-color-disabled;
            }
        }

        .warning {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        th, td {
            border: 1px solid @border-color-table;
            padding: 2px;
            text-align: center;
            min-width: 39px;
        }

        th {
            background-color: @bg-color-table-header;
            font-weight: bold;
        }

        tr:nth-child(even) {
            background-color: @bg-color-table-row-even;
        }

        tr:hover {
            background-color: @bg-color-table-row-hover;
        }

        .mui-button {
            .mui-button;
        }
    }

    .mui-button {
        background-color: @primary-color;
        color: @bg-color-main;
        border: none;
        padding: 5px 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
            background-color: @primary-hover-color;
        }

        &:disabled {
            background-color: @bg-color-disabled;
            color: @text-color-disabled;
        }
    }

    .mui-button-outline {
        background-color: @bg-color-main;
        color: @primary-color;
        border: 1px solid @border-color-primary;

        &:disabled {
            background-color: @bg-color-disabled;
            color: @text-color-disabled;
            border-color: @border-color-disabled;

            &:hover {
                background-color: @bg-color-disabled;
                color: @text-color-disabled;
                border-color: @border-color-disabled;
            }
        }

        &:hover {
            background-color: @bg-color-button-outline-hover;
            border-color: @border-color-primary;
        }
    }
}
