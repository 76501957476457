.information-classification--container {
    float: left;
    width: 95%;
    margin: 0 0 0 0;
    padding: 0 0 15px 0;
    border-bottom: 1px solid #eeeeee !important;

    h3 {
        padding: 5px 5px 5px 15px;
        font-weight: bold;
        margin: 0;
    }

    label {
        font-weight: 700;
        font-style: italic;
        margin: 0 0 0 15px;
    }

    img {
        margin: 0 0 0 5px;
        max-width: 50px;
    }

    article {
        margin: 0 0 5px 20px;
        padding: 5px;
        font-style: italic;
    }
}
