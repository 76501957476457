// gray gradient background
.fancy-padding { padding: 0 15px 15px 15px; }
.fancy-backdrop:extend(.fancy-padding) {
  &> .nav-tabs { margin-bottom: -1px; }
  .simple-gradient(@body-bg, @gray-lighter);
  border-bottom: 1px solid @nav-tabs-active-link-hover-border-color;
}

.extended-filter {
    &.hidden {
        display: block !important;
        visibility: visible !important;
        max-height: 0;
        opacity: 0;
        transition: opacity 0ms, max-height 0ms;
    }

    opacity: 1;
    max-height: 8000px;
    overflow: hidden;
    transition: opacity 300ms, max-height 0ms;
}

textarea { width: 100%; }
.pager-current { margin: 0 16px; }
.form-group { margin-bottom: 5px; } // removing spacing in forms, like mashie!
.separate { margin-top: 5px; margin-bottom: 5px; }  // and add back some of that removed space. :)
.center { text-align: center; }
.btn-checkbox input { position: absolute; left: -1000px; top: -1000px; width: 0; height: 0; }
@media (min-width: @screen-sm-min) {
  // i'm not sure this is needed, but i couldn't find anything better.
  .no-label:before {
      content: ".";
      display: block;
      visibility: hidden;
  }
}

.field-validation-error {
    //color: @state-danger-text;
    color: white;
    display: block;
    padding: 2px 4px;
    background: @state-danger-text;
}

.btn-checkbox input:checked ~ .unless-checked { display: none; }
.btn-checkbox input:not(:checked) ~ .if-checked { display: none; }

span.form-control.disabled { border-color: transparent; white-space: pre; box-shadow: none; }
input[type=checkbox].compact { margin: 0; }
