#fetchCustomOrderModal{
	table{
        margin-bottom:0;
		td{
			box-sizing:content-box;
			padding:5px;
			height:2em;
			select{
                option{
                    word-wrap:normal;
                }
			}
		}
	}
}