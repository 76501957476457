.clear-right-margin {
    margin-right: 0;
}

body.page {
    background: @gray-lighter;
    height: 100%;
}

.top-nav {
    padding-top: 6px;
}

.main-content {
    margin-left: 20px;
    margin-right: 20px;
}

label {
    margin-bottom: 0;
}

.separate-left { margin-left: 24px; }
.inline { display: inline; }

#main {
    background: white;

    &:after {
        content: '';
        display: block;
        position: absolute;
        background: white;
        left: 20px;
        right: 20px;
        top: 65px;
        bottom: 0;
        z-index: -1;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    }
}

@media screen and (max-width: @screen-lg-min) {
    .main-content {
        margin-left: 10px;
        margin-right: 10px;
    }

    #main:after {
        left: @sidebar-width + 9px;
        right: 10px;
    }
}

@media screen and (max-width: @screen-sm-min) {
    #sidebar { position: static !important; width: auto !important;}
    body { border-left: 0 !important; }
    #main:after { left: 0px !important; right: 0px; }
    .main-content { margin-left: 0; margin-right: 0; }
}

.level-1 > td:first-child { padding-left: 1em; }
.level-2 > td:first-child { padding-left: 2em; }
.level-3 > td:first-child { padding-left: 3em; }
.no-hpadding { padding-left: 0 !important; padding-right: 0 !important; }
.dim { opacity: 0.3; }