.kppalternartivemodel-container {
    width: 100%;
}

.kpp-ingredient--container {
    /*margin-top: -92px;*/
    position: fixed;
    right: 0;
}

.kppalternartivemodel-label-container {
    width: 80%;
    padding: 10px;

    span {
        margin: 10px 0 10px 0;
        float: left;
        width: 90%;
    }
}

.kppalternartivemodel-allergens {
    margin: 10px 0 0 0;
    width: 80%;
}

.kpp_alternarive-clicked {
    background: #B0C4DE;
    color: black;

    a {
        color: black !important;
    }

    td {
        color: black !important;
    }
}

.kppLabelMealName {
    cursor: pointer;
}

.kpp-filter-container {
    /*margin: 10px 0 15px 0;*/
    width: 100%;
}

.kpp-pnlCalendar-container {
    margin: 10px;

    select {
        margin-bottom: 10px;
    }
}

.kpp-pnlUnspecialize {
    width: 85%;
}

.kpp-filter--childen {
    float: left;
    width: 100%;
    margin: 5px 0 0 5px;
}

.kpp-pnlReplace-container {
    margin-top: 15px;
}

.kpp-pnlReplace {
    padding: 5px;
}

.kpp-filter-container-dgSummary-label {
    width: 100%;
    height: 28px;
    background: #E7EBF2;
    margin: 15px 0 0 0;
    border-radius: 3px;
    float: left;
    margin-bottom: 8px;

    strong {
        width: 100%;
        float: left;
        padding: 5px;
    }
}

.kpp-float-right {
    float: right;
}

.kpp-filter-container-pnlsummary-label {
    width: 100%;
    height: 28px;
    background: #E7EBF2;
    margin: 15px 0 0 0;
    border-radius: 3px;

    strong {
        width: 100%;
        float: left;
        padding: 5px;
    }
}

.kpp-buttons-container {
    width: 100%;
    float: left;
    padding: 5px 5px 5px 15px;
}

.kkp-filter--button {
    float: left;
    min-width: 150px;
    height: 25px;
    background: #E6EBF2;
    border-radius: 2px;
    margin: 0 25px 5px 0;

    i {
        line-height: 25px;
        padding: 0 0 0 6px;
        width: 100%;
        float: left;
    }
}

.kpp-filter-container-checkbox {
    margin: 5px 0 5px 0;
    float: left;
}

.kpp-filter-container-label {
    width: 100%;
    float: left;
    height: 28px;
    background: #E7EBF2;
    border-radius: 3px;

    strong {
        width: 100%;
        float: left;
        padding: 5px;
    }
}

.kpp-breakline {
    width: 100%;
    float: left;
    height: 28px;
    border-radius: 3px;
}

.kkp-pnlSummary {
    padding: 5px;
}

.kpp-pnlFilterDropDowns {
    width: 70%;
}

.kpp-filter--childen--dropdown {
    float: left;
}

.kpp-filter--childen--clear {
    float: left;
    border: 1px solid #7f9db9;
    border-radius: 3px;
    cursor: pointer;
    background-color: #e6ebf2
}

.kpp-filter--childen--clear:hover {
    background-color: white !important;
    color: black !important;
    text-decoration: none !important;
}

.kpp-filter--children--label {
    float: left;
    padding: 2px;
    font-weight: bold;
}

.KppFilterTab3 {
    float: left;
    width: 100%;
}

.kpp-datagrid {
    overflow: auto;
    height: auto;
    border: 1px solid #0F3166;
    font-weight: normal;
    font-size: 9pt;
    font-family: Arial;
    color: Black;
    text-decoration: none;
    border-collapse: collapse;

    tr {
        border: 1px solid #0F3166;
    }

    td {
        border: 1px solid #0F3166;
        padding-left: 5px;
    }

    th {
        border: 1px solid #0F3166;
        font-weight: bold;
        text-align: left;
        white-space: nowrap;
    }
}

.kpp-container {
    width: 100%;
    min-height: 1px;
}

.kpp-remove-padding {
    padding: 0px;
}

.kpp-fillter-item {
    padding-left: 0px !important;
}
.kpp-buton-clear-filter {
    margin-top:18px
}

.kpp-fixed-container {
    position: fixed;
    top: 250px;
    right: 40px;
}

@media only screen and (min-width: 1800px) {
    .kpp-fixed-container {
        width: 650px;
    }
}

@media only screen and (min-width: 1920px) {
    .kpp-fixed-container {
        width: 36%;
    }
}

@media only screen and (min-width: 2000) {
    .kpp-fixed-container {
        width: 40%;
    }
}

@media only screen and (min-width: 2500px) {
    .kpp-fixed-container {
        width: 37%;
    }
}
