#multipurchaser-container {
    #customerCategorySelect {
        margin-bottom: 15px;
    }

    input[type="checkbox"] {
        margin-right: 5px;
    }

    ul.customer-container, ul.customernames-container {
        list-style-type: none;
        padding-left: 0;
    }

    ul.customer-container {
        label {
            font-weight: normal;

            &.checked {
                font-weight: bolder;
                color: blue;
            }

            &:hover {
                background-color: rgb(62, 145, 255);
                color: white;
            }
        }
    }
}
