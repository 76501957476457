.mashie-view__text-line-banner{
    display:flex;
    .mashie-view__text-line-banner__visual{
        z-index:1;
        flex: 30 30 auto;
        height:9px;
        position:relative;
        align-self:center;
        margin-left:6px;

        &::after{
            content:'';
            width:100%;
            position:absolute;
            left:0;
            height: 9px;
            background: #e5e77f;
            background: linear-gradient(to bottom, hsla(61, 68%, 70%, .5) 0%, hsla(61, 68%, 70%, 1) 50%, hsla(61, 68%, 70%, 1) 100%);
        }

        &:last-child{
            flex: 1 1 auto;
            margin:0;
        }

    }
    .mashie-view__text-line-banner__optional{

    }

    h4{
        display:inline-block;
        font-size:.9em;
        font-weight:600;
    }
}