.error-page {
    background: #EEE;
}

.error-panel {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    background: white;
    margin: 0 auto;
    position: relative;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 32px;
    font-size: 21px;
    text-align: center;
    margin-bottom: 16px;
}

.error-panel p {
    max-width: 23em;
    margin: 0 auto;
}

.error-panel .code, .error-panel .code a {
    color: #CCC;
}

.error-panel .code {
    padding: 0;
    list-style: none;
    position: absolute;
    bottom: 26px;
    right: 32px;
    text-align: right;
    font-size: 16px;
}

.error-panel .support {
    padding: 0;
    list-style: none;
    text-align: left;
    margin: 43px 0 0;
}

.apology {
    padding: 32px 0;
}
