// ==============================================
// Misc helpers and shortcuts
// ==============================================

// Shortcuts --------------------------------

.border-radius(@radius:5px) {
    -webkit-border-radius: @arguments;
	-moz-border-radius: @arguments;
	border-radius: @arguments;
}

.box-sizing(@type: border-box) {
	-moz-box-sizing: @type;
	-webkit-box-sizing: @type;
	-ms-box-sizing: @type;
	box-sizing: @type;
}

.box-shadow(@shadow: 1px 1px 2px rgba(0,0,0,0.25)) {
	-webkit-box-shadow: @shadow;
	-moz-box-shadow: @shadow;
	box-shadow: @shadow;
}

.filter(@filter: grayscale(100%)) {
	-webkit-filter: @filter;
	-moz-filter: @filter;
	-ms-filter: @filter;
	-o-filter: @filter;
	filter: @filter;
}

.transition(@transition) {
	-webkit-transition: @transition;
	-moz-transition: @transition;
	transition: @transition;
}

.rotate(@rotation) {
	-webkit-transform: rotate(@rotation);
	-moz-transform: rotate(@rotation);
	-o-transform: rotate(@rotation);
	transform: rotate(@rotation);
}

.placeholder(@color: #DDD) {
	:-moz-placeholder {
		color: @color;
	}
	::-webkit-input-placeholder {
		color: @color;
	}
}


// Helpers --------------------------------

.clearfix() when not (@using-ieclasses) {
	&:before,
	&:after {
	    content: "";
	    display: table;
	}
	&:after {
	    clear: both;
	}
	*zoom: 1;
}

.clearfix() when (@using-ieclasses) {
	&:before,
	&:after {
	    content: "";
	    display: table;
	}
	&:after {
	    clear: both;
	}
	.ie6 &, .ie7 & {
		zoom: 1;
	}
}

.inline-block() when (@using-ieclasses) {
	display: inline-block;
	.ie7 & {
		display: inline;
		zoom: 1;
	}
}

.inline-block() when not (@using-ieclasses) {
	display: inline-block;
	*display: inline;
	*zoom: 1;
}

.ir() {
	border: 0;
	font: 0/0 a;
	text-shadow: none;
	color: transparent;
	background-color: transparent;
}

.hidden() {
	display: none !important;
	visibility: hidden;
}

.visually-hidden() {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	&.focusable:active,
	&.focusable:focus {
	    clip: auto;
	    height: auto;
	    margin: 0;
	    overflow: visible;
	    position: static;
	    width: auto;
	}
}

.size(@thesize) {
	width: @thesize;
	height: @thesize;
}

.size(@width, @height) {
	width: @width;
	height: @height;
}

// System --------------------------------

.nudge-l( @pos ) when ( @pos = 0 ) {}
.nudge-l( @pos ) when not ( @pos = 0 ) { left: @pos; }
.nudge-r( @pos ) when ( @pos = 0 ) {}
.nudge-r( @pos ) when not ( @pos = 0 ) { right: @pos; }
.nudge-t( @pos ) when ( @pos = 0 ) {}
.nudge-t( @pos ) when not ( @pos = 0 ) { top: @pos; }
.nudge-b( @pos ) when ( @pos = 0 ) {}
.nudge-b( @pos ) when not ( @pos = 0 ) { bottom: @pos; }
