.heading-stripe {
    position: relative;
    margin: 12px 0;
    font-weight: bold;
}
    
.heading-stripe > * {
    position: relative;
    z-index: 1;
    background: white;
    padding: 0 8px 0 0;
}

.heading-stripe:before {
    display: block;
    content: " ";
    background: #e9ed97;
    border-bottom: 1px solid #e8da79;
    position: absolute;
    left: 0;right: 0;top: 50%;bottom: 0;
    height: 9px;
    margin-top: -5px;
}

.breadcrumb-items > *:not(:first-child):before {
    display: inline;
    content: "/ ";
}
