.mashie-dropdown {
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 3px;
    outline: none;
    width: 100%;
}

.person-dropdown {
    padding: 3px 7px;

    &.deactivated {
        color: red;
    }
}
