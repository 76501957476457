@media (min-width: 0px) {
    .DayMenuSpecificationTableContainer {
        float: left;
        width: 100%;
    }

    .BarChartContainer {
        float: left;
        width: 100%;
    }
}

@media (min-width: 1610px) {
    .DayMenuSpecificationTableContainer {
        float: left;
        width: 66.66666667%;
    }

    .BarChartContainer {
        float: left;
        width: 33.33333333%;
    }
}
