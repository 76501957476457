.hierachyCopyBasketModal {
    width: 500px;
}

.hierachyCopyBasketModal-header {
    height: auto !important;
    min-height: 40px;
}

.hierachyCopyBasketModal-header--label {
    float: left;
    padding: 5px;
}

.hierachyCopyBasketModal-header--icon {
    float: right;
    padding: 8px;
    cursor: pointer;
}

.hierachyCopyBasketModal-body {
    padding: 0;
}

.hierachyCopyBasketModal-container--type {
    margin: 10px 0 0 0;
}

.hierachyCopyBasketModal-container--region {
}

.hierachyCopyBasketModal-container--district {
}

.hierachyCopyBasketModal-container--search {
    margin-top: 15px;
}

.hierachyCopyBasketModal-container--button {
    margin-top: 15px;

    button {
        float: right;
        margin: 0 41px 0 0;
    }

    label {
        font-style: italic;
        float: right;
        margin: 0 20% 0 0;
        color: red;
        font-size: 9pt;
        font-weight: normal;
    }
}

.hierachyCopyBasketModal-container--result {
    margin: 15px 0 10px 0;
}

.hierachyCopyBasketModal--select {
    float: left;
    margin: 0 0 5px 5px;
    width: 55%;
}

.hierachyCopyBasketModal--label {
    float: left;
    width: 35%;
}
