
.flex {
    display: flex;
}

.flexcolumn {
    .flex;
    flex-direction: column;
}

.flexrow {
    .flex;
}



.flexform-row {
    .flex;
    align-items: center;
    margin-bottom: 8px;

    .row-group {
        .flex;
        align-items: center;
    }

    .dropdown {
        flex-basis: 400px;
        width: 400px;
        margin: 0 5px;
        max-width: 400px;
    }

    textarea {
        width: 385px;
        max-width: 400px;
        height: 100px;
        margin: 0;
    }
}

.articleControl {
    .flexLabel {
        flex-basis: 120px;
    }
}

.customerAdminPage-information {
    padding: 10px;
    .flexLabel {
        flex-basis: 153px;
    }
}


.TagPrintDialogueLayout {
    margin-top: 10px;

    button, input[type=button], input[type=submit] {
        margin: 0;
    }

    .row-group {
        .flex;
        align-items: center;
        flex-basis: 140px;
        padding-right: 5px;
        margin-left: 5px;
    }

    .divider {
        border: thin solid silver;
        opacity: 0.5;
        width: 100%;
        margin: 10px 0;
    }


    .flexLabel {
        font-weight: bold;
        flex-basis: 140px;
    }

    .input-field {


        input[type=text] {
        }


        input[type=checkbox] {
            margin: 0;
            margin-right: 5px;
        }
    }
}
