@sidebar-width: 179px;

body.page {
    border-left: @sidebar-width solid white;
}

#sidebar {
    > .logo {
        margin: 11px 0;
        max-width: 126px;
    }

    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: @sidebar-width;
    min-height: 100%;
    background: white;
    font-size: 14px;
}

#main:after { left: @sidebar-width + 19px; }

.main-menu-item {
    text-align: left;

    &.main-menu-item--btn_Logout > a {
        color: @brand-primary;
    }


    > a {
        display: block;
        padding: 10px 12px 10px 29px;
        color: #000;

        &[disabled] {
            cursor: not-allowed !important;
            background-color: #e0e0e0;
            color: #898989
        }
    }

    > a, > a:hover {
        text-decoration: none;
    }

    &.active, &:hover {
        //transition: background 300ms, color 270ms 30ms;
        background: @brand-primary;

        > a {
            color: white;

            &[disabled] {
                color: #898989;
            }
        }
    }
}

@media screen and (max-width: @screen-lg-min) {
    #main:after {
        left: @sidebar-width + 10px;
        right: 10px;
    }
}
