.information-servinginstruction--container {
    border-bottom: 1px solid #eeeeee !important;
    margin: 0 0 15px 0 !important;
    width:95%;

    h3 {
        padding: 5px 5px 5px 15px;
        font-weight: bold;
        margin: 0;
    }

    h4 {
        font-weight: bold;
        margin: 5px 0 5px 25px;
    }

    article {
        margin: 5px 0 5px 20px;
        padding: 5px;
        font-style: italic;
    }
}
.information-servinginstruction--label{
    display:none;
}