//styling som ska gälla för allt. dvs standard style

select {
    padding: 1px;
    border-radius: 3px;
}


input[type="text"], input:not([type]) { //alla textboxar
    margin-right: 2px;
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
    padding: 0 0 0 5px;

    &.date {
        padding: 2px 7px;
    }
}


&.monospace {
    width: auto;

    option {
        font-family: "monospace", Monaco, monospace;
    }
}


//overrides

.form-control {
    color: black; //todo rättar ett css-fel som jag inte hittar... Jimmy
}

@import "../components/buttons.less";

/*.ui-dialog-titlebar-close {
    font-family: FontAwesome !important;
    &:before {
        content: "\f00d";
        font-weight: normal;
        color: @blue;
    }
}*/
