.inventory-export__confirm-dialog {
  font-size: 1em;
  ul {
    li {
      &:first-child {
        margin-top: 1em;
      }
      font-weight: bold;
    }
  }
  .confirm-dialog__footer {
    margin-top: 2em;
    border-top: 1px solid black;
    width: 100%;
    text-align: right;
    button {
      margin: 0.5em 0.4em 0.5em 0;
      padding: 0.4em 1em;
      border: 1px solid #c5c5c5;
      background: #eaedf8;
      font-weight: normal;
      color: #454545;
      &:hover {
        background-color: #edededed;
      }
    }
  }
}

.inventory-export__confirm-dialog--stock {
}

.inventory-export__confirm-dialog--orgs {
}
