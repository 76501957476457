.nutTableContainer {
    width: 542px;
    display:inline-block;
    vertical-align:top;
}
.nutPieChartContainer{
    display:inline-block;
    width:calc(~"100% - 547px");
}

.nutPieChartContainer, .information-piechart--container, .recipe-information-piechart--container {
    .nutrientPieChartWrapper, .information-chart {
        @media only screen {
            padding: 20px;
        }

        height: 100%;

        .fat-wrapper {
            .chart-legend ul li .legend-description {
                div:not(.ratio) {
                    width: 12em;
                }
            }
        }

        .all-wrapper {
            .chart-legend ul li .legend-description {
                div:not(.ratio) {
                    width: 8em;
                }
            }
        }

        .canvas-wrapper {
            @media only screen {
                width: 100%;
                margin-bottom: 2.5em;
            }
            @media print {
                width: 50%;
                float: left;
            }

            h3 {
                @media only screen {
                    text-align: center;
                }
            }

            @media print {
                img {
                    width: 100%;
                }
            }


            .chart-legend {
                margin-top: 1em;

                ul {
                    @media print {
                        padding-left: 0px;
                    }
                    list-style: none;
                    display: flex;
                    flex-direction: column;

                    li {
                        display: flex;
                        align-items: center;
                        padding: .25em;
                        width: 30em;

                        .legend-color {
                            width: 1.75em;
                            height: 1.75em;
                            border-radius: 1px;
                            margin-right: 1em;
                        }

                        .legend-description {
                            display: flex;

                            .ratio {
                                font-weight: bold;
                                color: #24221F;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1850px){
    .information-piechart--container, .recipe-information-piechart--container{
        .information-chart{
            .canvas-wrapper{
                    width:50%;
                    float:left;
                }
        }
    }
}

@media print {
    .information-piechart--container, .recipe-information-piechart--container {
        .information-chart {
            .canvas-wrapper {
                max-width: 50%;
            }
        }
    }
}

@media (min-width: 1350px){
    .nutPieChartContainer{
        .nutrientPieChartWrapper{
            .canvas-wrapper{
                    width:50%;
                    float:left;
                }
        }
    }
}

@media print {
    .nutPieChartContainer {
        .nutrientPieChartWrapper {
            .canvas-wrapper {
                max-width: 50%;
            }
        }
    }
}