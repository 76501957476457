
.canvas-container {
    padding: 10px;
    width: 100%;
    min-height: 85%;
    margin-top: 25px;

    .canvas-el {
        margin-top: 10px;
    }
}

.order_customer-title {
    width: 50%;
    text-align: left;
    padding: 2px 0 0 10px;
    float: left;
}

.order_container {
    width: 100%;
    padding: 10px 0 0 0;
}

.datepicker-search {
    padding: 0 0 0 1px;
    margin: 0 0 0 5px;
    cursor: pointer;
}

.order_totalportions {
    float: right;
    width: 50%;
    text-align: right;
    padding: 2px 10px 0 10px;
}

.oneWrapper {
    min-height: 90%;

    .onePortionSum {
        text-align: center;
        font-size: 60px;
        color: gray;
    }

    .oneMenualtName {
        padding: 40px 0 0 10px;
        font-size: 25px;
        color: gray;
    }
}


.order_totaldays {
    float: left;
    width: 50%;
    text-align: left;
    padding: 2px 0 0 10px;
}

.mealorder_datepicker {
    width: 50%;
    float: right;
    text-align: right;
    padding: 0 15px 0 0;

    input {
        cursor: pointer;
        padding: 0px !important;
        width: 75px !important;
        height: 20px !important;
    }
}
