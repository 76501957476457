@import '../mashiecolors.less';

#mail-message {
    padding: 8px;
    margin-top: -24px;

    .show-on-print {
        display: none;
    }
}


body.modal-open {
    overflow: hidden;
    padding-right: 0 !important;
}

#mm-modal {
    padding-right: 0 !important;

    .modal-dialog {
        .modal-content {
            max-height: 90vh;
            position: relative;

            .modal-header {
                height: auto !important;
                padding: 0 !important;
            }

            .modal-body {
                overflow-y: scroll;
                overflow-x: hidden;
                padding: 0 0 20px 0;

                .toolbar {
                }

                .section {
                    padding: 0 20px 0 20px;

                    .icon, .header, .text {
                        display: inline-block;
                        font-size: 1.2em;
                    }

                    .header {
                        font-weight: bold;
                        min-width: 8em;
                    }

                    .icon {
                        min-width: 2em;
                    }

                    .message-row {
                        display: flex;
                        align-items: center;
                        margin-bottom: 1em;
                    }

                    &.recipients {
                        margin-bottom: 1em;

                        .organizations {
                            select {
                                width: 15em;

                                &[disabled] {
                                    -webkit-appearance: none;
                                    border: none;
                                    font-size: 1.2em;
                                    padding: 0;
                                }
                            }
                        }

                        #mm-check-all-recipients {
                            float: left;
                            height: 24px;

                            input {
                                margin-top: 1px;
                                margin-left: 39px;
                                margin-right: 14px;
                                float: left;
                            }
                        }

                        .search {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            float: right;

                            select {
                                border-radius: 0;
                                padding: 0;
                                border-right: none;
                            }

                            .input {
                                background-color: @mashie-blue;
                                color: white;
                                text-align: center;

                                span {
                                    padding: 0 5px;
                                    width: 5em;
                                }
                            }

                            .input input {
                                min-width: 15em;
                                border-style: solid;
                                border-width: 1px;
                                border-color: rgb(169, 169, 169);
                                margin-right: -2px;
                            }

                            select, .input input {
                                height: 2em;
                            }
                        }

                        .results {
                            border-bottom: 1px solid black;
                            border-top: 1px solid black;
                            margin-top: 24px;

                            ul {
                                list-style: none;
                                padding: 0;
                                margin: 0;
                                overflow-y: scroll;
                                max-height: 20em;

                                li {
                                    display: flex !important;
                                    align-items: center;
                                    height: 0;
                                    opacity: 0;
                                    cursor: pointer;
                                    background-color: white;
                                    transition: opacity .5s ease-in, height .5s ease-in, background-color .2s ease-out;

                                    &:nth-child(odd) {
                                        background-color: @odd-row;
                                    }

                                    &.show {
                                        height: 3em;
                                        opacity: 1;
                                    }

                                    &.even-row {
                                        background-color: white;
                                    }

                                    &.odd-row {
                                        background-color: @odd-row;
                                    }

                                    &:hover {
                                        background-color: #cad0d9;
                                    }

                                    input {
                                        margin: 0;
                                    }

                                    span, input {
                                        margin-right: 1em;
                                    }

                                    span.fa {
                                        margin-left: 1em;

                                        &.List:before {
                                            content: "\f0c0";
                                            font-family: FontAwesome;
                                        }

                                        &.User:before {
                                            content: "\f007";
                                            font-family: FontAwesome;
                                        }
                                    }

                                    .recipient, input {
                                        vertical-align: middle;
                                    }
                                }
                            }
                        }
                    }

                    .error {
                        height: 0;
                        opacity: 0;
                        color: red;
                        transition: opacity 1s ease-in-out;

                        &.active {
                            height: auto;
                            opacity: 1;
                        }
                    }

                    &.message {
                        .header, .icon {
                            align-self: flex-start;
                            line-height: 1.5em;
                        }

                        textarea {
                            height: 10em;
                        }
                    }

                    &.attachments {
                        h2, button {
                            display: inline-block;
                            vertical-align: middle;
                            margin: 0;
                            margin-right: 5px;
                        }

                        button {
                            background-color: @mashie-blue;
                            color: white;
                            border-style: none;
                            border-width: 1px;
                            border-radius: 2px;
                        }

                        ul {
                            overflow-y: scroll;
                            max-height: 15em;
                            list-style: none;
                            margin: 1em 0 0 0;
                            padding: 0;

                            li {
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                font-size: 1.2em;
                                height: 2em;
                                transition: background-color .1s ease-out;

                                &:nth-child(odd) {
                                    background-color: @odd-row;
                                }

                                .fa {
                                    color: @warninig-red;
                                }

                                span {
                                    margin-right: 1em;

                                    &.item-type {
                                        font-weight: bold;
                                    }
                                }

                                &:hover {
                                    background-color: #cad0d9;
                                }
                            }
                        }
                    }
                }

                .success {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 9999px;
                    transition: all 1s ease-in-out;
                    right: 0;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    font-weight: bold;
                    font-size: 4em;
                    z-index: 6;
                    background-color: @mashie-green;

                    &.sent {
                        left: 0;
                    }
                }

                .processing {
                    display: none;
                    background-color: rgba(0,0,0,0.6);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    align-items: center;
                    justify-content: center;
                    font-size: 5em;
                    z-index: 5;
                    color: @mashie-green;

                    &.submitting {
                        display: flex;
                    }
                }
            }

            .modal-footer {
                button.btn-primary {
                }
            }
        }
    }

    @media(min-width: 768px) {
        .modal-dialog {
            width: 1000px;
        }
    }
}

#mm-basket-modal {
    padding-right: 0 !important;
    position: absolute;

    .modal-dialog {
        margin: 0;
        position: relative;
        z-index: 10;
        width: 100%;

        .modal-content {
            max-height: none;

            .modal-header {
                height: 4em !important;
                background-color: @mashie-blue;
                padding: 0.5em 20px 0 15px !important;

                button, button span {
                    color: white;
                }
            }
        }

        .modal-body {
            margin-top: 1em;

            ul {
                list-style: none;
                margin: 1em 0 0 0;
                padding: 0;

                li {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    font-size: 1.2em;
                    height: 2em;
                    transition: background-color .1s ease-out;

                    &:nth-child(odd) {
                        background-color: @odd-row;
                    }

                    .fa {
                        margin-left: 1em;
                        color: @ok-green;
                    }

                    span {
                        margin-right: 1em;

                        &.item-type {
                            font-weight: bold;
                        }
                    }

                    &:hover {
                        background-color: #cad0d9;
                    }

                    &.empty-basket {
                        margin-left: 1em;
                        background: inherit;
                        font-weight: bold;
                        color: @warninig-red;
                    }
                }
            }
        }
    }

    .modal-backdrop {
        z-index: 9;
        opacity: 0.5;
        background-color: black;
    }
}

@media print {
    #mail-message {
        margin-top: 10px;

        textarea, select, option, input {
            border: none !important;
        }

        .show-on-print {
            display: inline;
        }

        .hide-on-print {
            visibility: hidden;
            display: none;
        }
    }

    .modal-backdrop, .dashboard-reading {
        display: none;
    }

    .print-section, .print-section * {
        visibility: visible;
    }


    .print-section div:last-child {
        page-break-after: auto;
    }

    .print-section {
        position: absolute;
        top: 0;
        left: 0;
        height: auto;
        border: blue 1px solid;

        .section {
            padding: 0 20px 0 20px;

            .icon, .header, .text {
                display: inline-block;
                font-size: 1.2em;
            }

            .header {
                font-weight: bold;
                min-width: 8em;
            }

            .icon {
                min-width: 2em;
            }

            .message-row {
                display: flex;
                align-items: center;
                margin-bottom: 1em;
            }

            &.recipients {
                margin-bottom: 1em;

                .organizations {
                    select {
                        width: 15em;

                        &[disabled] {
                            -webkit-appearance: none;
                            border: none;
                            font-size: 1.2em;
                            padding: 0;
                        }
                    }
                }

                .search {
                    border-bottom: 1px solid grey;
                    margin-bottom: 1em;

                    select, .input {
                        display: none;
                    }
                }

                .results {
                    border-bottom: 1px solid black;

                    ul {
                        list-style: none;
                        padding: 0;
                        margin: 0;

                        li {
                            display: inline-block !important;
                            align-items: center;
                            height: 0;
                            opacity: 0;
                            cursor: pointer;
                            background-color: white;
                            transition: opacity .5s ease-in, height .5s ease-in, background-color .2s ease-out;

                            &:nth-child(odd) {
                                background-color: @odd-row;
                            }

                            &.show {
                                height: 3em;
                                opacity: 1;
                            }

                            &:hover {
                                background-color: #cad0d9;
                            }

                            input {
                                margin: 0;
                            }

                            span, input {
                                margin-right: 1em;
                            }

                            span.fa {
                                margin-left: 1em;

                                &.List:before {
                                    content: "\f0c0";
                                    font-family: FontAwesome;
                                }

                                &.User:before {
                                    content: "\f007";
                                    font-family: FontAwesome;
                                }
                            }

                            .recipient, input {
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }

            label.error {
                display: none;
            }

            &.message {
                textarea {
                    overflow: auto;
                    resize: none;
                    height: 20em;
                }

                input, textarea {
                    padding: 0;
                    margin: 0;
                    border: none;
                    box-shadow: none;
                }

                .icon, .header {
                    align-self: flex-start;
                }
            }

            &.attachments {
                button {
                    display: none;
                }

                ul {
                    padding: 0;
                    list-style: none;

                    li {
                        height: 3em;
                    }
                }
            }
        }
    }
}

#dashboard-new-message {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 2px 4px;
    position: relative;
    float: left;
    line-height: 1.5em;
    cursor: pointer;

    &:hover {
        background-color: #e6e6e6;
        border-color: #adadad;
    }
}
