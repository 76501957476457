@import "../../mashiecolors.less";
@import "../../animations.less";

@lighten_darken_percent: 5%;



.bugreportpage {


    

    .fixedAnimation{
        animation : blinkgreen infinite 4s linear;
        padding: 3px;
        display: inline-block;
    }


    .bugtracker {

        .bugRow {

            

            &.confirmed {
                background-color: @lightblue;

                &:hover {
                    background-color: darken(@lightblue,@lighten_darken_percent);
                }
            }

            &.triage {
                background-color: @red-lite;

                &:hover {
                    background-color: darken(@red-lite,@lighten_darken_percent);
                }
            }

            &.fixed {
                background-color: @green-light;

                &:hover {
                    background-color: darken(@green-light,@lighten_darken_percent);
                }
            }

            &.acknowledged {
                background-color: @blue1;

                &:hover {
                    background-color: darken(@blue1,@lighten_darken_percent);
                }
            }

            &:hover {
                background-color: @white-rock;
                cursor: pointer;
            }
        }
    }

    h2.mashie {
        margin: 0;
        padding: 10px;
        background-color: @blue;;
        color: white;
        font-size: 1.6em;
        margin-bottom: 4px;
    }

    .buglist {
        /*padding :0!important;*/
        /*border-left : 10px solid @blue1 !important;*/
        border-right: 5px solid @blue !important;
        /*border-bottom : 10px solid @blue1 !important;*/
    }
}
