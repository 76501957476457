.cmscontent {
    padding: 13px 28px;
    .screen-reader-text { position: absolute; top: -100000px; left: -100000px; }
    img { max-width: 100%; }
    .cmscontent > :first-child { margin-top: 0; }
    .entry-content h3 { font-size: 15px; }
    .entry-meta { text-align: center; }
    .entry-meta > *:not(:last-child) {
		margin-right: 24px;
		display: inline-block;
    }
    .fa { vertical-align: middle; }
    .wysiwyg-text { max-width: 60em; }
}
