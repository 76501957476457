
.information--print {
    float: right;
    padding: 10px 15px 0 0;

    :hover {
        cursor: pointer;
    }

    i {
        font-style: normal;
    }
}

#information-print-modal {

    .information-print-modal--header {
        margin: 0;
        font-weight: bold;
        padding: 6px 0 0 12px;
    }

    .information-print-modal--content {
        width: 30%;
        margin: 15% 0 0 35%;
    }

    .information-modal-print--button {
        float: right;
        width: 80px;
        border: 0;
        height: 31px;
        color: #fff;
        background: #95b417;
    }

    .information-print-modal--print {
        margin: 0 5px 0 0;
        .information-modal-print--button;
    }

    .information-print-modal--dismiss {
        margin: 0 10px 0 0;
        .information-modal-print--button;
    }

    .information-print-modal--checbox {
        width: 100%;
    }

    .information-print-modal--container {
        height: 35px;
        border-bottom: 1px solid #c0c0c0;

        h4 {
            margin: 0;
            padding: 10px 5px 5px 25px;
        }
    }

    .information-print-modal-checbox--container {
        height: 115px;
        padding: 15px 15px 0 60px;
    }
}

@media print {
    body.has-print-clone > *:not(#print-clone) {
        display: none !important;
    }
     body.has-print-clone{
        visibility: visible;
        overflow: hidden;
    }

    a[href]:after {
        content: none !important;
    }

    .information-nutrient--container {
        display: inline;
    }

    .recipe-information--h4-removed {
        margin: 0 0 10px 0 !important;
        padding: 0 !important;

        h4 {
            display: none !important;
        }

        div {
            display: none !important;
        }

        article {
            padding: 10px 10px 10px 0px;
            font-size: 14px;
            font-style: italic;
        }
    }

    //daymenu-information-no-print--left
    .information-print--left {
        padding: 0 !important;
        float: left;
        width: 50% !important;
    }

    .information-print--classification {
         width: 40px !important;
    }

    .information-print--image {
        width: 92% !important;
        margin: 20px 0 0 0 !important;
        text-align: center !important;
    }

    .information-print--right {
        padding: 0 0 0 25px !important;
        width: 49% !important;
        float: left !important;
    }

    .information-print--article {
        width: 80% !important;
        font-size: 11px !important;
    }

    //daymenu-information-print-no-break
    .information-print--break {
        page-break-inside: avoid !important;
        page-break-after: avoid !important;
    }

    .information-print-meal-content {
        border-bottom: 1px solid #eeeeee;
        margin: 10px 0 10px 0 !important;
    }

    .information-print-recipe--info {
        margin: 0 0 10px 0 !important;
        padding: 0 !important;

        h4 {
            font-size: 13px !important;
            font-weight: bold !important;
        }

        div {
            position: relative !important;
            display: inline-block !important;
            width: 10% !important;

            span {
                margin: 6px 0 0 2px !important;
            }

            label {
                position: absolute !important;
                text-align: center !important;
                top: 8px !important;
                left: 6px !important;
                font-size: 14px !important;
                font-weight: bold !important;
            }
        }

        article {
            margin: 0 !important;
            padding: 0 !important;
            font-size: 12px !important;
            width: 80% !important;
            font-style: italic !important;
        }
    }

    .information-print-servinginstruction--container {
        border-bottom: 1px solid #eeeeee;
        margin: 0 0 15px 0;
    }


    .information-print-kitchen--info {
        width: 100% !important;

        div {
            margin: 0 0 15px 0 !important;
        }

        border-bottom: 1px solid #eeeeee;
    }

    .information-print-recipe--container {
        h3 {
            font-weight: bold !important;
            margin: 0;
        }

        section {
            width: 95%;
            padding: 15px 0 15px 5% !important;
            margin-bottom: 10px !important;
            border-bottom: 1px solid #eeeeee !important;
        }
    }


    .information-print--margin {
        margin: 15px 0 0 0 !important;
    }

    .information-print--width {
        width: 90% !important;
    }

    //daymenu-information-no-print--padding-left
    .information-print--padding-left {
        padding-left: 0 !important;
    }

    //daymenu-information-no-print--margin-left
    .information-print--margin-left {
        margin-left: 0 !important;
    }

    .information--border {
        border: none !important;
    }

    .information-print-remove-left {
        .information-print--margin-left;
        .information-print--padding-left;
    }

    .information-no-print--container {
        display: none !important;
    }

    .information--no-print {
        display: none !important;
    }

    //daymenu-information-print--label
    .information-print--label {
        font-size: 15px !important;
        font-weight: bold !important;
    }

    .information-print--label-small {
        font-size: 13px !important;
        font-weight: bold !important;
    }

    //daymenu-information-no-print--no-border
    .information-print--border {
        border: none !important;
    }

    .information-print--header {
        height: 10px !important;
        float: none !important;
        margin: 0 0 10px 0 !important;
    }

    .information-print--padding {
        padding: 0 !important;
    }

    .information-print-recipe--border {
        margin-bottom: 15px !important;
        border-bottom: 1px solid #eeeeee;
    }

    .information-print--select {
        width: 200px !important;
        overflow: hidden !important;
        border: 0px !important;

        select {
            width: 250px !important;
            border: 0px !important;
            background: transparent !important;
            margin: 0 0 0 -5px !important;
        }
    }

    #recipe_information, #recipe_information *, #daymenu_information, #daymenu_information * {
        visibility: visible;
    }
}
