form input:focus {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

form input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    background-color: #ffffff !important;
}

form input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    background-color: #ffffff !important;
    -webkit-text-fill-color: #333;
}

.login-container {
    max-width: 996px;

    .form-group label {
        font-weight: 500;
    }
}

.flagcontainer {
    height: 40px;
    width: 100%;
    display: block;
}

.flagwidth {
    padding: 10px;
    float: left !important;
    width: 30%;

    &:hover {
        cursor: pointer;
    }
}

.flaglabelwidth {
    font-size: 14px;
    padding: 15px 0px 10px 10px;
    float: right !important;
    width: 70%;

    &:hover {
        cursor: pointer;
    }
}

.mashieinfocontainer {
    padding-left: 120px !important;
}

.mashieinfoheader {
    font-size: 20px;
    color: #333333;
}

.login-font {
    /*font-family: 'Open Sans', sans-serif !important;*/
}

.login-btn {
    width: auto;
    padding: 0 12%;
    border-radius: 2px;

    &:focus {
        user-select: none;
        border: none;
        outline: none;
    }

    &:active {
        user-select: none;
        border: none !important;
        outline: none !important;
    }
}


.mashieinfo {
    font-size: 14px;
    color: #6E6E6E;
}

.flag-icon {
    font-size: 30px;
    color: rgba(193, 193, 193, 1);
    cursor: pointer;
}


.flag-dd {
    margin-bottom: 5px;
}

.flag-list {
    height: 245px;
    border-radius: 0 !important;
    border: 1px solid #eee;
    box-shadow: none;
}

.flag-contarinertop {
    margin-top: -5px;
}

.flag-contarinerbottom {
    height: 43px;
}

.flagcontainer:hover {
    background: #eeeeee;
    cursor: pointer;

    .flagsprite {
        &.flagsprite-sv {
            background-position: -(0 * 14px) -(0 * 11px);
        }

        &.flagsprite-da {
            background-position: -(1 * 14px) -(0 * 11px);
        }

        &.flagsprite-no {
            background-position: -(2 * 14px) -(0 * 11px);
        }

        &.flagsprite-en {
            background-position: -(3 * 14px) -(0 * 11px);
        }

        &.flagsprite-de {
            background-position: -(4 * 14px) -(0 * 11px);
        }

        &.flagsprite-fi {
            background-position: -(5 * 14px) -(0 * 11px);
        }
    }
}

.login-top {
    text-align: center;
    padding: 0 40px;
}


.login-language {
    margin-top: -26px;
    line-height: 0;
    margin-right: 15px;
}

.login-form {
    padding-left: 40px;
    font-size: 14px;
    position: relative;
    padding-top: 230px;
}

.logintext {
    padding-left: 40px;
    z-index: 999;
    color: #ffe263;
    font-size: 26px;
    position: absolute;
    text-align: left;
    margin-top: 70px;
}

.welcome {
    padding-left: 40px;
    z-index: 999;
    color: #ffffff;
    font-size: 50px;
    position: absolute;
    text-align: left;
}

.login-splash {
    width: 100%;
    z-index: 0;
    height: 220px;
    position: absolute;
    top: 100px;
    bottom: 0;
    right: 0;
    background-image: url(../../../Images/inloggning_header.png);
    background-size: cover;
}

.login {
    padding-top: 60px;
    /*position: relative;
    border: 2px solid #3f5a85;
    border-radius: 8px 8px 0 0;
    padding-top: 26px;
    padding-bottom: 24px;
    margin-top: 6px;*/
    &:before {
        /*position: absolute;
        top: 0; left: 0; right: 0;
        height: 25px;
        display: block;
        .simple-gradient(#3f5a85, #103166);*/
    }
}

@media screen and (min-width: @screen-sm-min) {
    .login-top {
        text-align: left;
        margin-top: 40px;
    }

    .login-language {
        float: right;
        width: auto;
    }
}

@media (max-width: 550px) {
    .login-form {
        font-size: 14px;
        padding-top: 200px;
        padding-left: 15% !important;
        padding-right: 15% !important;
    }
}

@media (max-width: 768px) {
    .logintext {
        padding-left: 40px;
        font-size: 20px;
        top: 85px;
    }

    .welcome {
        font-size: 35px;
        top: 100px;
    }

    .login-form {
        font-size: 14px;
        padding-top: 200px;
    }

    .login-language {
        margin-top: -30px;
        margin-right: 10px;
        text-align: right;
        line-height: 0;
    }

    .logger {
        text-align: left;
        padding-top: 10px;
    }

    .login-splash {
        width: 100%;
        z-index: 0;
        background-position: center;
        height: 220px;
        position: absolute;
        top: 60px;
        bottom: 0;
        right: 0;
        background-image: url(../../../Images/inloggning_header.png);
        background-size: cover;
    }
}
