@import 'mashiecolors.less';

.notification-control {
  box-shadow: 0 7px 18px rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  margin-bottom: 1em;
  position: fixed;
  text-align: center;
  bottom: 0;
  right: 0;
  width: 300px;
  background: white;
  height: 60px;
  display: flex;
  vertical-align: middle;
  z-index: 9999;
  transition: width 0.5s;

  .icon-section {
    width: 25%;
    background-color: @mashie-green;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: white;
      font-size: 4em;
      vertical-align: middle;
    }
  }

  .content-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
  }
}

.toast-control {
  margin-right: 30em;
  bottom: -100px;
  transition: bottom 0.5s ease-out;
  .toast-content {
    padding: 2em;
  }
}

.globalization-control {
  margin-right: 3em;
  display: none;
  transition: width 0.5s;

  .globalization-section {
    transition: width 1.5s;
  }

  .globalization-content {
    transition: width 0.25s;
    button {
      width: 80%;
      color: white;
      height: 3em;
      border: none;
      padding: 5px 15px;
      background-color: @mashie-green;
      transition: width 0.25s, height 0.5s, color 0.5s;
      white-space: nowrap;

      &:active {
        background-color: white;
        color: @mashie-green;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.title {
  display: inline-block;
  height: 34px;
  font-weight: bold;
  margin-right: 2em;
}
.switch {
  position: relative;
  display: inline-block !important;
  width: 60px;
  height: 34px;
  vertical-align: middle;

  input {
    display: none;
  }
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: @mashie-green;
}

input:focus + .slider {
  box-shadow: 0 0 1px @mashie-green;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.refresh-control {
  position: relative;
  display: flex;
  margin-top: 3em;
  box-shadow: none;
  .globalization-section {
    background-color: white;
    span {
      color: @mashie-green;
    }
  }
}
