
.item-information--info {
    h4 {
        float: left;
        margin: 0;
        padding: 10px 0 8px 15px;
        font-weight: bold;
    }

    section {
        position: relative;
        display: inline-block;
        width: 10%;

        span {
            margin: 6px 0 0 3px;
        }

        label {
            position: absolute;
            text-align: center;
            top: 7px;
            left: 8px;
            font-size: 14px;
            font-weight: bold;
        }
    }
}
