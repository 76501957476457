.hierachyDeleteBasketModal{
    width: 400px
}

.hierachyDeleteBasketModal-header--label{
    float: left;
     padding: 2px;
}

.hierachyDeleteBasketModal-header--icon{
    float: right; 
    padding: 4px; 
    cursor: pointer;
}
.hierachyDeleteBasketModal-body{
    padding: 0;
}
.hierachyDeleteBasketModal-body--label{
    float: left;
    margin: 10px 0 0 20px;
}
.hierachyDeleteBasketModal-body--select{
    float: left; 
    margin: 10px 0 15px 10px;
}