@media print {
  body.page {
    border-left-width: 0;
    background: none;
  }

  nav.navbar-top {
    display: none;
  }

  .nav-toolbox {
    display: none;
  }

  nav#sidebar {
    display: none;
  }

  #mainDiv, #main {
    margin: 0;
  }

  .modal-open .modal {
    overflow: hidden;
  }

  .print-hide {
    display: none;
  }
}