.addToBasketModal{
    width: 863px;
}
.addToBasketModal-header--label{
    float: left; 
    padding: 2px;
}
.addToBasketModal-header--icon{
    float: right; 
    padding: 4px;
     cursor: pointer
}