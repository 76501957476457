.tableAllergens {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;

    th, td { border: 1px solid #eeeeee; }
    input, label { padding: 2px 0 2px 5px; }

    tbody {
        tr:nth-child(even) { background: #FFF }
        tr:nth-child(odd) { background: #EEEEEE }

        td:nth-child(2) { text-align: center; }
        td:nth-child(3) { text-align: center; }
    }

    thead {
        label { font-weight: bold; }
        .firstHeader { width: 34%; }
        .secondHeader { 
            width: 26%;
            text-align: center;
        }
        .thirdHeader { 
            width: 30%;
            text-align: center;
        }
    }

    .groupName { font-style: italic; }
    .allergenName { padding-left: 20px; }
}