
.information-meal--header {
    height: auto;
    min-height: 30px;
    background: #bdc3c7;

    label {
        font-size: 15px;
        margin: 0;
        line-height: 20px;
        padding: 5px 5px 5px 15px;
        color: #000000;
        font-weight: bold;
    }

    i {
        float: right;
        line-height: 20px;
        padding: 5px 10px 5px 9px;
        font-size: 16px;
    }
}

.information-meal--content {
    width:95%;
}