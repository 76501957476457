#objectBasketDiv {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    position: fixed;
    z-index: 100;
}

#objectBasketDiv .SearchControlClass{
    height: 380px;
    overflow-y: auto;
    overflow-x:hidden;
}

#objectBasketDiv .search-list{
    height: auto;
}

