@import "mashiecolors.less";

@backgroundColor1: #E7EBF2;
@hoverBackgroundColor1: #FFFFFF;
@borderColor1: #839DBA;
@fontColor1: #454552;
@fontColorDisabled1: #A4AEC0;


.mashiebutton {

    &:disabled {
        color: @fontColorDisabled1;
        cursor: not-allowed;

        &:hover {
            background-color: @backgroundColor1;
            color: @fontColorDisabled1;
            cursor: not-allowed;
        }
    }



    &:hover {
        cursor: pointer;
    }
}

.mashiebutton1 {
    .mashiebutton;
    margin-right: 5px;
    margin-top: 5px;
    vertical-align: top;
    border: 1px solid @borderColor1;
    background-color: @backgroundColor1;
    border-radius: 2px;
    padding: 2px 5px;
    font-size: 1em;
    font-family: Arial;
    min-width: 80px;
    color: @fontColor1;

    &::first-letter {
        //funkar inte med webforms (asp:button)
        text-transform: uppercase;
    }

    &:hover {
        background-color: @hoverBackgroundColor1;
    }
}

.mashiebutton1a {
    .mashiebutton1;
    min-width: 0;
}

.mashieSearchButton1 {
    .mashiebutton1;
    min-width: 0;
    margin: 0 0 2px 5px;
    padding: 2px 5px;
}

.mashiebutton-green {
    .mashiebutton;
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 10pt;
    color: white;
    font-family: Arial;
    text-decoration: none;
    text-align: center;
    border-style: none;
    background-color: @mashie-green;
}

.mashiebutton2 {
    .mashiebutton;
    background-color: #e7ebf7;
    padding: 5px 10px;
    font-family: Arial;
    color: black;
    border: 1px solid #a9a9a9;
}

.mashiebutton-fontAwesome {
    font-family: FontAwesome !important;
    font-size: 1.2em;
    padding: 0;
    background-color: transparent;
    border: none;


    &:focus {
        outline: none;
    }

    &:not(:before) {
        opacity: 0;
        color: #4949a8;
    }

    &:before {
        content: "\f00d ";
        height: 0;
        opacity: 1 !important;
    }

    &:hover {
        text-decoration: none;
        cursor: pointer;

        &:disabled {
            cursor: not-allowed!important;
        }
    }
}


.mashiebutton-remove {
    .mashiebutton-fontAwesome;
    min-width: 30px;
    color: #b00000;

    &:not(:before) {
        opacity: 0;
    }

    &:before {
        content: "\f014";
        height: 0;
        opacity: 1 !important;
    }


    &:hover {
        color: #b00000;
    }
}

.mashiebutton-new {
    .mashiebutton-fontAwesome;

    &:before {
        content: "\f016";
        color: #4949a8;
    }
}

.mashiebutton-download {
    .mashiebutton-fontAwesome;

    &:before {
        content: "\f019";
        color: #4949a8;
    }
}

.mashiebutton-search {
    .mashiebutton-fontAwesome;

    &:before {
        content: "\f002";
        color: #4949a8;
    }
}

.mashiebutton-add {
    .mashiebutton-remove;
    font-size: 1.5em;
    &:focus {
        outline: none;
    }

    &:before {
        content: "\f0c7";
        color: #4949a8;
    }
}

.mashiebutton-add2 {
    .mashiebutton-remove; 

    &:focus {
        outline: none;
    }

    &:before {
        content: "\f067";
        color: #4949a8;
    }
}

.mashiebutton-edit {
    .mashiebutton-remove; 

    &:focus {
        outline: none;
    }

    &:before {
        content: "\f044";
        color: #4949a8;

    }
   
}

.mashiebutton-close {
    .mashiebutton-fontAwesome;
    padding: 10px;

    &:before {
        font-size: 1.5em;
        content: "\f00d";
        font-weight: normal;
        color: white !important;
    }
}

.mashiebutton-info {
    .mashiebutton-fontAwesome;

    &:before {
        content: "\f128";
        color: #4949a8;
    }
}

.betafeature {
    &:after {
        content: "Beta";
        position: relative;
        left: -10px;
        top: -7px;
        display: inline-block;
        cursor: default;
        color: blue;
        font-weight: bold;
        font-size: 0.8rem;
        text-decoration: underline;
        transform: rotate(20deg);
        font-family: Arial;
        text-transform: uppercase;
        //  background-color: lightgray;
        //  border: 1px dotted blue;
        padding: 0px;
        border-radius: 10px;
    }
}

.alphafeature {
    .betafeature;

    &:after {
        content: "Alpha";
        color: red;
    }
}

.newfeature {
    .betafeature;

    &:after {
        font-size: 1rem;
        content: "NEW";
        color: @mashie-green;
    }
}

.mashiebutton-tab {
    .mashiebutton;
    margin: 0;
    background-color: rgb(231,235,247);
    font-weight: bold;
    border: 1px solid @borderColor1;
    min-width: 80px;

    &.selected {
        background-color: @hoverBackgroundColor1;
        border-bottom-color: white;
    }
}



.picture-button.search {
    padding: 3px 9px 1px 28px !important;
}

.picture-button.search {
    background-image: url(../../img/icon-magnifier.png) !important;
    background-position: 6px center !important;
    background-repeat: no-repeat !important;
}

.picture-button {
    color: black !important;
    text-decoration: none !important;
    position: relative !important;
    background-color: #e6ebf2 !important;
    border: 1px solid #7f9db9 !important;
    border-radius: 3px !important;
    padding: 2px 9px !important;
    vertical-align: middle !important;
}

a.picture-button {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.picture-button:hover {
    background-color: white !important;
    color: black !important;
    text-decoration: none !important;
}

.picture-button[disabled] {
    background-color: #e6ebf2 !important;
    opacity: 0.6 !important;
}


.mashiebutton-basket {
    .mashiebutton-fontAwesome;
    color: @blue;

    &:before {
        content: "\f291";
    }

    &:hover {
        color: @mashie-green;
    }
}

.mashiebutton-shortcut {
    .mashiebutton-fontAwesome;
    font-size: 1.4rem;
    /*transform: rotate(15deg);*/
    color: @blue;
    outline: none;


    &:before {
        content: url('data:image/gif;base64,R0lGODlhEgASAPcAAAAAAD9cjMrQ2f///wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAASABIAAAhhAP8JHEiwoMGDCAUGWMiwYUOCAQZInEhRYgCIFh1GtIhxQAABIAVs5Dhw40eQI0kqtBhyIcWLJVmeFDkR5kqPEWea7Djy5M6YFT3S9Nix4sefN432LBq0JkSNUBNKnYowIAA7') /*../../../Images/link_button.gif*/; //"\f112";
    }

    &:hover {
        color: @mashie-green;
        text-decoration: none;
        cursor: pointer;
    }
}

.mashiebutton-bars {
    .mashiebutton-fontAwesome;
    font-size: 1.9rem;
    color: @blue;

    &:before {
        content: "\f0c9";
    }

    &:hover {
        color: Darken(@blue,5%);
    }
}

.mashiebutton-circle {
    .mashiebutton-fontAwesome;
    padding: 4px;
    font-size: 2.6rem;
    /*color: lighten(@blue,50%);
        background-color: @blue;
         border-radius: 50%;*/
    &:before {
        content: "\f10c";
    }

    &:hover {
        color: darken(@blue,10%);
    }
}

.mashiebutton-circle-selected {
    .mashiebutton-circle;

    &:before {
        content: "\f058";
    }
}

.mashiebutton-down {
    .mashiebutton-fontAwesome;
    transition: all 0.3s ease;

    &:before {
        content: "\f078";
        color: #4949a8;
    }

    &.is-open {
        transform: rotate(-180deg);
    }
}