body, textarea, select, option, input {
    font-weight: normal;
    color: black;
    font-family: Arial;
}

.ui-datepicker .ui-state-active {
    background: #375382 !important;
}

    .ui-datepicker .ui-state-active a:link {
        color: #FFF !important;
    }

.ui-datepicker .ui-state-highlight {
    background: #95B417 !important;
    border-color: #95B417 !important;
    color: #000 !important;
}

.ui-datepicker-next, .ui-datepicker-prev {
    background: #375382;
}

.ui-dialog .ui-widget-header {
    background-color: #375382 !important; /*override jqueryui default*/
    color: white;
}

html :not(.ui-dialog-titlebar).ui-widget-header .ui-state-active .PageLabelClass {
    color: black !important;
}


.classic fieldset legend {
    font-size: 14px !important;
    margin-left: 0.5em;
    display: inline;
    width: auto;
    border-width: 0;
    padding: 0 4px;
    margin-bottom: 0;
}

.classic input[type=checkbox], .classic input[type=radio] {
    margin: 3px;
    /*    position: relative;*/
}

.classic label {
    font-weight: normal;
}

.classic fieldset {
    padding: 0.75em;
    margin: 0.2em;
    margin-left: 18px;
    border: 1px solid #999;
}

body#ext-gen4 {
    margin: 8px;
}

.searchpage-searchselectionalt {
    margin-top: 4px;
}

.searchpage-freetextalt {
    margin-top: 3px;
}

.searchpage-dateto {
    margin-top: 2px;
}

.searchpage-ddlaltdifficulty {
    width: 193px;
    height: 20px;
    margin-top: 3px;
}

.wholesalecontrol-ddlpricelist {
    margin-left: 4px;
}

.searchpage-searchtextfree {
    margin-left: 4px;
    margin-top: 3px;
    height: 20px;
    padding: 0 0 0 2px;
}

.wholeconfigadmin-ddlconfig {
    margin-left: 6px;
}

.invetoryadminpage-label5margin {
    margin-left: 8px;
}

.wholeconfigadmin-ddlist {
    margin-left: 4px;
}

.global-marginleft {
    margin-left: 4px;
}

.searchpage-dropdownresultlist {
    margin-left: 4px;
}

.advSearchRecipeAltTextBox {
    width: 197px !important;
    height: 20px;
    margin-top: 3px;
}

.searchpage-advSearchAltitems {
    width: 197px;
    height: 20px;
}

.advSearchAltTextBox {
    width: 193px !important;
    height: 20px;
    margin-top: 3px;
}

.advSearchBox {
    margin-bottom: 3px;
}

.allergnerFont {
    font-size: 10px;
}

.classificationTable {
    max-height: 375px !important;
    overflow: auto;
}

.allergnerTable {
    width: 29%;
}

.CalcBoxClass {
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.CalcLabelClass {
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.ImageButtonClass {
}

.CalendarBrowse {
    line-height: 0;
}

.classic img {
    vertical-align: top;
}

.classic hr {
    color: black;
    margin: 0.5em 0;
}

.ImageButtonClass-Disabled {
    opacity: 0.5;
    filter: alpha(opacity=50); /*IE*/
    zoom: 1; /* needed to trigger "hasLayout" in IE if no width or height is set */
}

#mainDiv {
    background: white;
    margin: 0 18px;
}

.DataGridClass {
    overflow: auto;
    height: auto;
    border: 1px solid #F0EDDF;
    font-weight: normal;
    font-size: 9pt;
    font-family: Arial;
    color: Black;
    text-decoration: none;
    border-collapse: collapse;
}

    .DataGridClass tr {
        border: 1px solid #F0EDDF;
    }

        .DataGridClass tr.order--fail {
            background-color: #fc521b;
        }

        .DataGridClass tr.order--success {
            background-color: #79b516;
        }

table.collapse-table {
    line-height: 0;
}

.DataGridClassNoMinCol {
    min-width: 0px !important;
}

.DataGridClass th {
    border: 1px solid #F0EDDF;
    font-weight: bold;
    text-align: left;
    white-space: nowrap;
}

.DataGridClass td {
    border: 1px solid #F0EDDF;
}

.DataGridClass thead tr .header {
    background-image: url(../../Images/datatables/sort_both.png);
    background-repeat: no-repeat;
    background-position: center right;
    cursor: pointer;
}

.DataGridClass thead tr .headerSortUp {
    background-image: url(../../Images/datatables/sort_asc.png);
}

.DataGridClass thead tr .headerSortDown {
    background-image: url(../../Images/datatables/sort_desc.png);
}

.DataGridClass tbody tr.odd {
    background-color: #EEEEEE;
}

.CalcButtonClass {
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.TableClass {
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
    padding-bottom: 3px;
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 3px;
}

.TableHeaderClass {
    font-weight: bold;
    font-size: 9pt;
    font-family: Arial;
}

.HeaderClass {
    font-weight: bold;
    font-size: 13pt;
    font-family: Arial;
}

.TableBigHeaderClass {
    font-weight: bold;
    font-size: 11pt;
    font-family: Arial;
}

.ValidatorClass {
    font-weight: normal;
    font-size: 9pt;
    color: red;
    font-family: Arial;
    text-decoration: none;
}

.TableItemClass {
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
    padding-bottom: 3px;
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 3px;
}

.TableButtonClass {
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
    padding-bottom: 3px;
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 3px;
}

.ListBoxClass {
    border-right: 0px;
    border-top: 0px;
    font-weight: normal;
    font-size: 9pt;
    border-left: 0px;
    color: black;
    border-bottom: 0px;
    font-family: Arial;
    text-decoration: none;
}

.ButtonClass {
    font-weight: bold;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.GreenButtonTextClass {
    font-weight: bold;
    font-size: 10pt;
    color: white;
    font-family: Arial;
    text-decoration: none;
    text-align: center;
    border-style: none;
}

.GreenButtonTableClass {
    border: 0px none;
}

    .GreenButtonTableClass tr {
        border: 0px none;
    }

    .GreenButtonTableClass td {
        border: 0px none;
    }


.DataGridButtonClass {
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.MenuButtonClass {
    font-weight: bold;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
    width: 100%;
}

.PrintButtonClass {
    font-weight: bold;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
    width: 100%;
}

.PrintHeaderClass {
    font-weight: bold;
    font-size: 11pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.DropDownListClass, select.DropDownListClass {
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
    margin: 0 0 0 4px;
}

.TextBoxClass {
    margin-left: 4px;
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
    width: 100%;
}

.ui-datepicker-trigger {
    margin-top: 3px;
}

.isDirtyTextBoxClass {
    display: none;
}

.SafeTextBoxClass {
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.DateTextBoxClass {
    height: 20px;
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
    margin-left: 4px;
}

.DiscreetTextBoxClass {
    border-style: none none solid solid;
    border-width: 1px;
    border-color: #C0C0C0;
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
    width: 100px;
}

.FixedWidthTextBoxClass {
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Courier New;
    text-decoration: none;
}

.RadioButtonClass {
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

    .RadioButtonClass label {
        margin-left: 3px;
        font-weight: bold;
        position: relative;
        top: -2px;
    }

.PageLabelClass {
    font-weight: bold;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.PageDelimiter {
    border-color: LightGrey;
    border-width: 1px;
    border-style: Solid;
    width: 440px;
}

.PageLabelLockTimeMenu {
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
    color: gray;
}




.PageLabelBlueClass {
    font-weight: bold;
    font-size: 9pt;
    color: Navy;
    font-family: Arial;
    text-decoration: none;
}

.PageLabelWhiteClass {
    font-weight: bold;
    font-size: 9pt;
    color: White;
    font-family: Arial;
    text-decoration: none;
}

.PageButtonClass {
    font-weight: bold;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.PageTextClass {
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.InfoTextClass {
    font-weight: normal;
    font-size: 9pt;
    color: #c00000;
    font-family: Arial;
    font-style: italic;
}

.PageListBoxClass {
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.PageSmallCommentClass {
    font-weight: normal;
    font-size: 7pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.PageSmallVarningClass {
    font-weight: normal;
    font-size: 7pt;
    color: red;
    font-family: Arial;
    text-decoration: none;
}

.BoldLabelClass {
    font-weight: bold;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.LabelClass {
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.SmallLabelClass {
    font-weight: normal;
    font-size: 7pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.LoginLinkClass {
    font-weight: bold;
    font-size: 9px;
    font-family: Verdana;
    text-decoration: none;
}

.SmallBoldLabelClass {
    font-weight: bold;
    font-size: 7pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.CheckBoxClass {
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.MenuHyperClass {
    padding-right: 4px;
    padding-left: 4px;
    font-weight: bold;
    font-size: 9pt;
    padding-bottom: 4px;
    color: Black;
    padding-top: 4px;
    font-family: Arial;
    text-decoration: none;
}

    .MenuHyperClass:visited {
        padding-right: 4px;
        padding-left: 4px;
        font-weight: bold;
        font-size: 9pt;
        padding-bottom: 4px;
        color: Black;
        padding-top: 4px;
        font-family: Arial;
        text-decoration: none;
    }

    .MenuHyperClass:link {
        padding-right: 4px;
        padding-left: 4px;
        padding-bottom: 4px;
        padding-top: 4px;
        font-weight: bold;
        font-size: 9pt;
        color: Black;
        font-family: Arial;
        text-decoration: none;
    }

.DescrTextClass {
    font-weight: normal;
    font-size: 9pt;
    color: Black;
    font-family: Arial;
    text-decoration: none;
}

.LoginLabelClass {
    font-weight: bold;
    font-size: 14pt;
    color: dimgray;
    font-family: Arial;
    text-decoration: none;
}

.LoginTextClass {
    font-weight: bold;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.LoginTextBoxClass {
    font-weight: bold;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.LoginButtonClass {
    font-weight: bold;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.HyperLinkClass:link {
    padding-bottom: 4px;
    padding-top: 4px;
    font-weight: normal;
    font-size: 9pt;
    color: #0f3166;
    font-family: Arial;
    text-decoration: underline;
}

.HyperLinkClass:Visited {
    padding-bottom: 4px;
    padding-top: 4px;
    font-weight: normal;
    font-size: 9pt;
    color: #0f3166;
    font-family: Arial;
    text-decoration: underline;
}

.HyperLinkClass {
    padding-bottom: 4px;
    padding-top: 4px;
    font-weight: normal;
    font-size: 9pt;
    color: #0f0f0f;
    font-family: Arial;
    /*text-decoration: underline;*/
}

.NavigationClass {
    padding-bottom: 4px;
    padding-top: 4px;
    font-weight: normal;
    font-size: 8pt;
    color: #777777;
    font-family: Arial;
    text-decoration: none;
}

    .NavigationClass:hover {
        padding-bottom: 4px;
        padding-top: 4px;
        font-weight: normal;
        font-size: 8pt;
        color: #000000;
        font-family: Arial;
        text-decoration: none;
    }

.NewsTextClass {
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.NewsLinkClass:Link {
    font-weight: normal;
    font-size: 9pt;
    color: Navy;
    font-family: Arial;
    text-decoration: none;
}

.NewsLinkClass:Visited {
    font-weight: normal;
    font-size: 9pt;
    color: Navy;
    font-family: Arial;
    text-decoration: none;
}

.NewsDateClass {
    font-weight: normal;
    font-size: 9pt;
    color: Navy;
    font-family: Arial;
    text-decoration: none;
}

.TextLineClass {
    font-weight: bold;
    font-size: 9pt;
    color: Black;
    font-family: Arial;
    text-decoration: none;
}

.MenuDateLabelClass {
    font-weight: bold;
    font-size: 18pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.MenuMealNameLabelClass {
    font-weight: normal;
    font-size: 14pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.MenuMealTypeLabelClass {
    font-weight: normal;
    font-size: 16pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.SmallWarningClass {
    font-weight: bold;
    font-size: 7pt;
    color: red;
    font-family: Arial;
    text-decoration: none;
}

.SmallInfoClass {
    font-weight: normal;
    font-size: 7pt;
    color: Gray;
    font-family: Arial;
    text-decoration: none;
}

    .SmallInfoClass:Link {
        font-weight: normal;
        font-size: 7pt;
        color: Gray;
        font-family: Arial;
        text-decoration: underline;
    }

    .SmallInfoClass:Visited {
        font-weight: normal;
        font-size: 7pt;
        color: Gray;
        font-family: Arial;
        text-decoration: underline;
    }

.DataGridRow {
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
}

.MultiGridItem {
    background-color: #ffffff; /*#e5e5e5; */
}

.MultiGridAltItem {
    background-color: #f0f0f0;
}

.MultiGridSelected {
    background-color: #eee8aa;
}

.SelectedClientButtonColumn:Link {
    color: black;
    background-color: palegoldenrod;
    font-weight: bold;
}
/* active link */
.UnSelectedClientButtonColumn:Link {
    color: black;
    background-color: white;
    font-weight: normal;
}
/* active link */
.LinkButtonClass {
    font-weight: bold;
    font-size: 9pt;
    color: Blue;
    font-family: Arial;
    text-decoration: none;
}

.DataGridClassCompressed {
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
    BORDER-TOP: black 1px solid;
    BORDER-RIGHT: black 1px solid;
    BORDER-BOTTOM: black 1px solid;
    BORDER-LEFT: black 1px solid;
    PADDING-RIGHT: 4px;
    PADDING-LEFT: 4px;
    PADDING-BOTTOM: 4px;
    PADDING-TOP: 4px;
}

.publicMenuSideBar {
    background-color: #ffffcc;
}

.publicMenuSideBarRadioButtonList {
    font-weight: normal;
    font-size: 10pt;
    color: Black;
    font-family: Arial;
    margin-bottom: 10px;
    /*padding-bottom: 5px;*/
}

.publicMenuSideBarWeekTitle {
    font-weight: bold;
    font-size: 10pt;
    color: black;
    font-family: Arial;
    text-align: center;
}

.publicMenuSideBarDayTitle {
    font-weight: bold;
    font-size: 8pt;
    color: Black;
    font-family: Arial;
}

.publicMenuSideBarTodayTitle {
    font-weight: bold;
    font-size: 9pt;
    color: #999900;
    font-family: Arial;
}

.publicMenuSideBarMealText {
    font-weight: normal;
    font-size: 8pt;
    color: Black;
    font-family: Arial;
}

.DivCover {
    background-color: white;
    filter: alpha(opacity=50);
    opacity: 0.5;
    -moz-opacity: 0.5;
    z-index: 25;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
}

.DivWindowCover {
    background-color: white;
    filter: alpha(opacity=50);
    opacity: 0.5;
    -moz-opacity: 0.5;
    z-index: 75;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
}

select.smallSize, input.smallSize {
    width: 45%;
}

select.largeSize, input.largeSize {
    width: 95%;
}

hr.hr {
    height: 2px;
    background: #ADAAAD;
    color: #ADAAAD;
    border: 0 none;
    border-style: none;
    border-width: 0;
    margin: 10px 0;
    text-align: left;
    width: 100%;
}

.WarningClass {
    font-style: italic;
    font-size: 9pt;
    color: red;
}

.ErrorClass {
    font-style: normal;
    font-size: 9pt;
    color: red;
    font-family: Arial;
}
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Ajax Toolkit customization
 */
.ajaxCalendar .ajax__calendar_container {
    border: 2px solid #506fa1;
    background-color: Lavender;
    color: Blue;
}

.ajaxCalendar .ajax__calendar_active {
    border: 1px dotted Blue;
}

.grayTabTheme .ajax__tab {
    display: inline !important;
    float: none !important;
}

.grayTabTheme .ajax__tab_header {
    background-color: #F7F3F7;
    font-family: "Helvetica Neue", Arial, Sans-Serif;
    font-size: 12px;
    display: block;
    color: #000;
    font-weight: bold;
    border-bottom: 1px solid #ADAAAD;
    padding: 0 20px;
}

    .grayTabTheme .ajax__tab_header .ajax__tab_outer {
        background: #E7EBF7;
        border: 1px solid #ADAAAD;
        border-bottom: 0 none;
        margin: 0 0.2em 0 0;
        position: relative;
        top: 0;
    }

    .grayTabTheme .ajax__tab_header .ajax__tab_inner {
        padding: 3px 18px 4px;
    }

.grayTabTheme .ajax__tab_active .ajax__tab_outer {
    background: #FFFFFF;
    position: relative;
    top: 1px;
}

.grayTabTheme .ajax__tab_active .ajax__tab_inner {
    padding: 4px 18px;
}

.grayTabTheme .ajax__tab_body {
    padding: 20px;
}
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * loading popup styling
 */
#loadingPopupBackground {
    display: none;
    position: fixed;
    _position: absolute; /* hack for internet explorer 6*/
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #FFFFFF;
    z-index: 1;
}

#loadingPopupContact {
    display: none;
    position: fixed;
    _position: absolute; /* hack for internet explorer 6*/
    background: #FFFFFF;
    z-index: 2;
    padding: 12px;
    font-size: 13px;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * fixedwidth
 */

.fixedwidth {
    font-family: Courier New, monospace;
    font-size: 9pt;
    font-weight: normal;
}

select.fixedwidth {
    font-family: Courier New, monospace;
    font-size: 9pt;
    font-weight: normal;
}

    select.fixedwidth option {
        font-family: Courier New, monospace;
        font-size: 9pt;
        font-weight: normal;
    }
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * dataTables
 */
.dataTables_wrapper {
    width: 650px;
    position: relative;
    min-height: 189px;
    _height: 263px;
    clear: both;
    font-size: 9pt;
    font-family: Arial;
}



.portiontypeadmin-body {
    padding-left: 18px;
}

.wholesalescontrol-config {
    margin-left: 4px;
}

.dataTables_length {
    display: none;
}

.dataTables_filter {
    float: left !important;
    position: relative;
    top: -5px;
    line-height: 15px;
}

    .dataTables_filter input {
        margin-left: 65px;
        width: 155px;
        line-height: 0px;
    }

.dataTables_info {
    float: right;
    margin: 10px 5px;
    text-align: right;
    height: 25px;
    line-height: 25px;
}

.dataTables_paginate {
    float: left;
    width: 350px;
    text-align: center;
    margin: 10px 0;
    text-align: left;
}

.paginate_disabled_previous, .paginate_enabled_previous, .paginate_disabled_next, .paginate_enabled_next {
    height: 19px;
    width: 19px;
    margin-left: 3px;
    float: left;
}

.paginate_disabled_previous {
    background-image: url('../../Images/datatables/back_disabled.jpg');
}

.paginate_enabled_previous {
    background-image: url('../../Images/datatables/back_enabled.jpg');
}

.paginate_disabled_next {
    background-image: url('../../Images/datatables/forward_disabled.jpg');
}

.paginate_enabled_next {
    background-image: url('../../Images/datatables/forward_enabled.jpg');
}

.paging_full_numbers {
    height: 25px;
    line-height: 25px;
}

    .paging_full_numbers span.paginate_button,
    .paging_full_numbers span.paginate_active {
        border: 1px solid #ADAAAD;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        padding: 2px 5px;
        margin: 0 3px;
        cursor: pointer;
        *cursor: hand;
    }

    .paging_full_numbers span.paginate_button {
        background-color: #E7EBF7;
    }

        .paging_full_numbers span.paginate_button:hover {
            background-color: #fff;
        }

    .paging_full_numbers span.paginate_active {
        background-color: #899ec9;
    }

.sorting, .sorting_asc, .sorting_desc {
    cursor: pointer;
    text-decoration: underline;
    color: #00e;
}

/* Don't forget to set GridLines="None" and BorderStyle="None"
    when you use this. */
.InnerDataGridClass {
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    height: 100%;
    font-weight: normal;
    font-size: 9pt;
    color: black;
    font-family: Arial;
    text-decoration: none;
    border-collapse: collapse;
}

    .InnerDataGridClass td {
        border: 1px solid #808080;
    }

    .InnerDataGridClass tr:first-child td {
        border-top: 0;
    }

    .InnerDataGridClass tr:last-child td {
        border-bottom: 0;
    }

    .InnerDataGridClass tr td:first-child {
        border-left: 0;
    }

    .InnerDataGridClass tr td:last-child {
        border-right: 0;
    }

.reportControl {
    overflow: hidden;
}

/* Dashboards */

/* STYLING */

.nonDashboardPageDiv {
    background-color: #FFFFFF;
}

.more {
    background-position: right top;
    /* background-image: url(../Images/more.gif); */
    background-repeat: no-repeat;
    padding-right: 10px;
}

.bbottom {
    border-bottom-style: dotted;
    border-bottom-width: 1px;
    border-bottom-color: #666666;
}

.emp {
    font-weight: bold;
}

.small {
    font-size: 11px;
}

.indent {
    margin-left: 15px;
    margin-bottom: 6px;
}

.bullet {
    list-style-type: square;
    list-style-position: inside;
}

.disablePadding {
    padding: 0px;
}

.bmargin {
    margin-bottom: 5px;
}

.alBottom {
    position: absolute;
    bottom: 0px;
}

.clearAll {
    clear: both;
}

.infoDiv h3 {
    padding-right: 15px;
    margin-bottom: 5px;
}

    .infoDiv h3 span {
        font-weight: normal;
        padding-left: 15px;
    }

div.icon-info {
    padding-left: 50px;
}

.icon-info {
    /* background-image: url(../Images/icon-info.png); */
    background-repeat: no-repeat;
    min-height: 48px;
    padding-left: 50px;
}

    .icon-info h2 {
        color: #0066CC;
    }

.bh:hover {
    color: #FFFFFF;
}

.pageSlogan {
    border: 1px solid #C0C0C0;
    font-size: 22px;
    line-height: 26px;
    background-color: #F0F0F0;
    padding: 5px;
    color: #666666;
}

/* END STYLING */

.widgetTest {
    color: yellow;
    background-color: yellow;
}

.widgetTypeCombo {
    font-weight: bold;
    margin-right: 10px;
}

#dashboardManagerDiv {
    font-weight: bold;
    padding-bottom: 2px;
    clear: both;
}

    #dashboardManagerDiv select {
        border: 1px none #CCCCCC;
        font-weight: bold;
        margin-right: 10px;
        color: #666666;
    }

    #dashboardManagerDiv span {
        padding-right: 10px;
    }


div.form h2 {
    display: block;
    height: 20px;
    padding-left: 5px;
    background-color: #EECCC8;
    background-repeat: repeat-x;
    color: #421915;
}

div.formContent {
    background-position: left bottom;
    background-attachment: scroll;
    background-repeat: no-repeat;
    overflow: hidden;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
}

.formNote {
    display: block;
    font-size: 10px;
    line-height: 15px;
    color: #989898;
}

a.formNote {
}

div.form, div.cnt {
    border: 1px solid #C0C0C0;
    padding: 5px;
    padding-bottom: 0px;
    margin-bottom: 5px;
}

    div.form.widget {
        background-color: Transparent;
        border: 1px none #C0C0C0;
        padding: 0px;
        padding-bottom: 0px;
    }

div.formRow {
    clear: both;
    width: 100%;
    margin-bottom: 9px;
    line-height: 20px;
}

div.formName {
    width: 160px;
    float: left;
    text-align: right;
    margin-right: 3px;
    margin-top: 1px;
}

div.formValue {
    overflow: hidden;
}

div.formCommand {
    padding: 5px;
    border-top: #d3d3d3 1px solid;
    clear: both;
    border: 1px solid #C0C0C0;
    background-color: #D1D1D1;
    margin-bottom: 5px;
    margin-top: 5px;
}

    div.formCommand.in {
        margin-left: -5px;
        margin-right: -5px;
        margin-bottom: 0px;
        margin-top: 0px;
    }

.bookSectionInfo h2 {
    display: block;
    font-weight: bold;
    font-size: 16px;
    color: white;
    padding-top: 5px;
}


.box {
    border: 1px solid #C0C0C0;
    padding: 5px;
    margin-bottom: 5px;
    background-color: #E5E5E5;
    overflow: hidden;
}

/* ORDER FORM */

#orderInfo {
    width: 300px;
    float: left;
}

#orderTotals {
    float: left;
    width: 255px;
    margin-left: 20px;
}

#orderForm div.formName {
    width: 120px;
}

#orderForm div.moneyName {
    width: 150px;
    float: left;
}

#orderForm div.moneyValue {
    width: 75px;
    overflow: hidden;
}

#orderInformation div.formValue {
    font-weight: bold;
}

/* END ORDER FORM*/

.bookSectionInfo a {
    background-position: center center;
    display: block;
    width: 150px;
    height: 150px;
    float: right;
    /* background-image: url(../Images/view-sample-sections.png); */
    background-repeat: no-repeat;
}

.bookSectionList a {
    color: #333333;
    font-size: 13px;
    display: block;
    padding: 4px;
    border: 1px solid transparent;
}

    .bookSectionList a.selected, .bookSectionList a:hover {
        border: 1px solid #CCCCCC;
        font-weight: bold;
        background-color: #E1E1E1;
        color: #333333;
    }

    .bookSectionList a:hover {
        font-weight: normal;
    }

ul.horiz li {
    background-position: left center;
    display: inline;
    padding-left: 10px;
    padding-right: 5px;
    /* background-image: url(../Images/square.png); */
    background-repeat: no-repeat;
}

ul.horiz#bookdownloads a {
    font-weight: bold;
}


/* ICON TITLE */

div.iconTitle h2 {
    background-position: left center;
    padding-left: 35px;
    background-repeat: no-repeat;
    min-height: 28px;
    display: block;
    padding-top: 4px;
}
/*
div.iconTitle.faq h2 {
    background-image: url(../Images/helpblue2.png);
}

div#authorCrit h2 {
    background-image: url(../Images/author-icon.png);
}

div#bookCrit h2 {
    background-image: url(../Images/book-icon.png);
}
*/

/* ICON TITLE */

/* OTHER */


div.switchModeDiv.footer {
    clear: left;
    padding-left: 24px;
}


div.switchModeDiv a {
    color: #CCCCCC;
}


.menu-title {
    background: #ebeadb;
    border-bottom: 1px solid #99bbe8;
    color: #aaddaa; /* #15428b; */
    font: bold 10px tahoma,arial,verdana,sans-serif;
    display: block;
    padding: 3px;
}

.theme {
    background-position: left center;
    display: block;
    background-repeat: no-repeat;
    padding-left: 27px;
    padding-bottom: 1px;
    padding-top: 1px;
}

/*
    .theme.Kalitte {
        background-image: url(../Images/theme-kalitte.png);
    }

    .theme.KalitteBlue {
        background-image: url(../Images/theme-kalitteblue.png);
    }

    .theme.Black {
        background-image: url(../Images/theme-black.png);
    }

    .theme.Slickness {
        background-image: url(../Images/theme-slick.png);
    }

    .theme.BlueSky {
        background-image: url(../Images/theme-blue.png);
    }

    .theme.Gray {
        background-image: url(../Images/theme-gray.png);
    }

    .theme.Olive {
        background-image: url(../Images/theme-access.png);
    }

    .theme.Mashie {
        background-image: url(../Images/theme-access.png);
    }

    .theme.Purple {
        background-image: url(../Images/theme-purple.png);
    }

    .theme.Slate {
        background-image: url(../Images/theme-slate.png);
    }

    .theme.Access {
        background-image: url(../Images/theme-access.png);
    }
    */

em.new {
    font-size: 9px;
    font-weight: bold;
    color: #FF0000;
}

div.siteMapDiv {
    float: left;
    color: #CCCCCC;
    margin-left: 310px;
    margin-top: 56px;
}

    div.siteMapDiv a {
    }

        div.siteMapDiv a:hover {
            color: #ffffff;
        }

    div.siteMapDiv .currentNavigation {
        font-size: 14px;
        color: #ffffff;
        font-weight: bold;
    }

    div.siteMapDiv .navigationNode {
        font-size: 9pt;
        color: #CCCCCC;
    }

.sampleDashboardBox img {
    border: 1px solid #666666;
    float: right;
    margin-top: 5px;
    margin-left: 5px;
}

/* END OTHER */

/* PRICE */

table.priceTable {
    width: 100%;
    font-size: 10px;
    line-height: 13px;
}

    table.priceTable td {
        height: 15px;
    }

        table.priceTable td.featureGroup {
            height: 18px;
            font-weight: bold;
        }

        table.priceTable td.price {
            padding: 2px;
            font-weight: bold;
            width: 75px;
        }

        table.priceTable td.license, table.priceTable td.licenseGroup {
            padding: 2px;
        }

        table.priceTable td.licenseGroup {
            font-weight: bold;
        }

        table.priceTable td.buynow {
            width: 100px;
        }

        table.priceTable td.edition {
            height: 18px;
            font-weight: bold;
        }

        table.priceTable td.breakLine {
            height: 1px;
            background-color: gray;
        }

a.buy {
    display: block;
    width: 100px;
    /*background-image: url('../../Images/buynow.png'); */
    background-repeat: no-repeat;
    width: 64px;
    height: 17px;
}

a.buyContact {
    display: block;
    width: 100px;
    /*background-image: url('../../Images/buy-contact.gif'); */
    background-repeat: no-repeat;
    width: 64px;
    height: 17px;
}

/* END PRICE */

/* GRID */

.dGrid {
    background-color: #aaddaa; /* #f5f5f5; */
    padding-right: 2px;
    padding-left: 2px;
    margin: 0px 10px 0px 0px;
    border-top: #dcdcdc 1px solid;
    border-right: #dcdcdc 1px solid;
    border-left: #dcdcdc 1px solid;
    border-bottom: #dcdcdc 1px solid;
    font-size: 10px;
    border-style: none;
    line-height: 18px;
}

    .dGrid TD {
        border-style: none;
        padding-right: 2px;
        padding-left: 2px;
        padding-bottom: 1px;
        padding-top: 1px;
    }

    .dGrid TH {
        border-style: none;
        padding: 1px 5px;
        /* background-image: url('../../Images/grid-header.gif'); */
        background-repeat: repeat-x;
        text-align: left;
        font-weight: bold;
        background-color: #aaddaa; /* #4F6280; */
        color: #aaddaa; /* #E4E4E4; */
    }

    .dGrid TR {
    }

.dGridAlt {
    background-color: #E6E6E6;
}

.dGridSel TD, .dGridSel TD a {
    background-color: #556a8b;
    color: #FFFFFF;
}

/* END GRID */

.x-portlet .x-panel-mc {
    padding-top: 0px !important;
}

.widget-description {
    margin: 2px;
    text-align: center;
    font-weight: bold;
    font-size: 11px;
    display: block;
    font-family: Tahoma;
}

.center-body {
    margin: auto;
    text-align: center;
}



.customStyledDashboard {
    background-color: transparent;
    /* background-image: url('../../Images/digital-bg-1.png'); */
    background-repeat: no-repeat;
    background-position: 0px 0px;
    margin-left: auto;
    margin-right: auto;
}

.home-dashboard {
    padding-bottom: 0px !important;
}

.widthInherit {
    width: inherit;
}

.ui-datepicker-trigger {
    vertical-align: bottom;
}

.floatLeft {
    float: left;
}

.floatRight {
    float: right;
}


/**** START CalendarControl ****/
.calendarControlMenu {
    padding-bottom: 5px;
}

.buttonEnabled {
    cursor: pointer;
}

.buttonDisabled {
    cursor: default;
}

.calendarControlMenu img {
    margin-bottom: -5px;
}

.calendarControlMenu .calendarControlDeleteBtn {
    margin-left: -1px;
}

.calendarControlMenu span {
    padding-left: 15px;
}

.calendarControlMenu .calendarSpanShowClassifications {
    padding-left: 0px;
}

.calendarControlMenu .calendarSpanPortionType {
    padding-left: 40px;
}

.calendarControlMenu .calendarCbShowClassifications {
    vertical-align: -2px;
}

.calendarControlWeekNumber {
    width: 20px;
}

.calendarControlWeek {
    height: 100%;
}

.calendarControlWeekDay {
    border-right: 1px solid #D3D3D3;
    border-top: 1px solid #D3D3D3;
    border-bottom: 1px solid #D3D3D3;
}

.calendarControlWeekNumber {
    border: 1px solid #D3D3D3;
}

.calendarControlWeekNumberText {
    vertical-align: middle;
    text-align: center;
    font-weight: bold;
}

.org {
    font-weight: normal;
    font-size: 10px;
    color: #666666;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
}

.EmptyDay {
    background-color: Gray;
}

.calendarControlNameSpecListView {
    font-weight: bold;
    text-decoration: underline;
}

.calendarControlTable td,
.calendarControlTable th {
    cursor: pointer;
}

.calendarControlWeekDayHeaderText {
    text-align: center;
    font-weight: bold;
    white-space: nowrap;
    padding: 0 2px;
    overflow: hidden;
}

.calendarControlTable {
    width: 95%;
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: fixed;
    min-width: 760px;
}

.calendarControlWeekDayDateText {
    text-align: right;
    padding-right: 2px;
}

.calendarControlCurrentDate {
    font-weight: bold;
    color: #95B300;
}

.calendarControlWeekDayAlternativeListItem {
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    padding-bottom: 2px;
}

.calendarControlMealGroupPictures {
    white-space: normal !important;
}

    .calendarControlMealGroupPictures img {
        height: 46px;
        width: 46px;
    }

.calendarControlSpecList ul {
    padding-left: 1px;
}

    .calendarControlSpecList ul li {
        list-style-type: none;
    }

.calendarControlAllergens {
    position: absolute;
    /*right: 20px;*/
}

    .calendarControlAllergens i {
        float: right;
        padding-left: 3px;
        background-color: transparent;
        height: 17px;
        width: 17px;
        color: #375382;
    }


.calendarControlClassificationPictures {
    margin-left: 62px;
    position: absolute;
    right: 0.5px;
}

    .calendarControlClassificationPictures img {
        float: right;
        padding-left: 3px;
        background-color: transparent;
        height: 17px;
        width: 17px;
    }

.calendarControlWeekDayAlternativeList {
    padding: 0 2px;
    margin: 0;
}

.calendarControlWeekDayWrapper {
    position: relative;
}

.calendarControlWeekDayAlternativesContainer {
    padding-bottom: 5px;
    overflow: hidden;
    width: 100%;
}

.calendarControlWeekDayHeaderContainer {
    width: 100%;
}

.calendarControlWeekDayStatus {
    position: absolute;
    bottom: 0px;
    height: 5px;
    width: 100%;
}

.calendarControlWeekDayStatusDone {
    background-color: #95b300;
}

.calendarControlWeekDayStatusStarted {
    background-color: #E4E573;
}

.calendarControlWeekDayStatusNotStarted {
    background-color: white;
}

.calendarControlShortName {
    font-weight: bold;
    display: inline-block;
    padding-left: 1px;
}

.redText {
    color: #FF4500;
}

.ui-draggable-helper {
    color: #fff;
    border: 1px dotted #000;
    padding: 1px;
    background: #0000FF;
    font-size: 1em;
    z-index: 10;
}

.drop-hoover {
    background-color: #95B300 !important;
}

.moveMenu {
    border: 1px solid #ccc;
    background-color: #eeeeee;
    position: absolute;
    z-index: 6000;
}

.menuAlt {
    height: 20px;
    width: 130px;
    color: black;
}

.moveMenuTableHoverRow:hover {
    background-color: #cccccc;
}

.hideElement {
    display: none;
}

.selectedDay {
    background-color: #B0C4DE;
}

.moveMenuTableBorderBottom {
    border-bottom: 1px solid #ccc;
}

.moveMenuTableBorderRight {
    border-right: 1px solid #ccc;
}

.moveMenuTableBorderTop {
    border-top: 1px solid #ccc;
}

.moveMenuTableAltImg {
    padding: 2px;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.hoverPointer:hover {
    cursor: pointer;
}

.noPmargin p, noPmargin div p {
    margin: 0;
    padding: 5px;
}
/**** END CalendarControl ****/

/************************************************/

/**** START BasketControl ****/

.basket-latest-item > span {
    display: block;
    overflow: hidden;
}

.basket-latest-item {
    width: 180px;
    height: 27px;
    background: #e6ebf2;
    display: inline-block;
    border-radius: 3px;
    font-size: 12px;
    padding: 6px 12px;
    overflow: hidden;
    white-space: nowrap;
}

.basketDialog a:focus {
    outline: none;
}

.basketDialog .ui-tabs {
    margin: -6px -10px;
    height: 415px;
}

.basketDialog .menuAlternativeSelectWrapper {
    padding-top: 33px;
    padding-left: 10px;
}

.basketDialog .removeFromBasket {
    margin-left: -4px;
}

/*Basket-tab*/
/*.basketDialog #basketTab {
    padding-left: 21px;
}

    .basketDialog #basketTab .basketSelectWrapper {
        padding-top: 10px;
        padding-bottom: 20px;
    }*/
.basketSelectWrapper select {
    margin-left: 58px;
    width: 155px;
}

.basketDialog #basketTab .basketTableWrapper {
    height: 310px;
    overflow: auto;
}

    .basketDialog #basketTab .basketTableWrapper .dataTable {
        width: 640px !important;
    }

    .basketDialog #basketTab .basketTableWrapper .dataTables_wrapper {
        padding-top: 10px;
        width: 640px;
    }

.basketControlTable .col1 {
    width: 3% !important;
}

.basketControlTable .col2 {
    width: 7% !important;
}

.basketControlTable .col3 {
    width: 64% !important;
}

.basketControlTable .col4 {
    width: 26% !important;
}

.basketDialog + div {
    margin-top: 0 !important;
}

.basketControlTable .classificationImages img {
    padding-right: 3px;
}

.basketDialog + .ui-dialog-buttonpane .ui-dialog-buttonset {
    float: left !important;
    padding-left: 18px;
}

/*Search-tab*/
.basketDialog #searchTab {
    /*padding-left: 21px;*/
}
    /**** END BasketControl ****/

    /************************************************/

    /**** START SearchControl ****/
    .basketDialog #searchTab .searchTableWrapper {
        height: 273px;
        overflow: auto;
        /*margin-top: 20px;*/
    }

        .basketDialog #searchTab .searchTableWrapper .searchControlTable {
            width: 640px;
        }

        .basketDialog #searchTab .searchTableWrapper .dataTable {
            width: 640px !important;
        }

        .basketDialog #searchTab .searchTableWrapper .dataTables_wrapper {
            padding-top: 10px;
            width: 640px;
        }

.searchControlTable input {
    text-align: center;
}

.searchControlTable .col1 {
    width: 3% !important;
}

.searchControlTable .col2 {
    width: 7% !important;
}

.searchControlTable .col3 {
    width: 64% !important;
}

.searchControlTable .col4 {
    width: 26% !important;
}

.searchControlTable .classificationImages img {
    padding-right: 3px;
}
/**** END SearchControl ****/

/************************************************/

/* (jquery.spin.js) */
.spinnerModal {
    display: none;
    position: fixed;
    z-index: 5000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #FFFFFF 50% 50% no-repeat;
    filter: alpha(opacity=50);
    opacity: 0.5;
    -moz-opacity: 0.50;
}

/************************************************/

/**** START JQUERY-CSS-OVERWRITE ****/
.ui-widget-overlay {
    background: #FFFFFF !important;
    opacity: 0.7 !important;
}
/**** END JQUERY-CSS-OVERWRITE ****/

.ui-multiselect {
    margin-top: 2px !important;
    height: 20px !important;
    background: white !important;
}

.ui-multiselectTmp {
    margin-right: 5px !important;
}

.ui-multiselect-menu {
    padding: 2px !important;
    border: 1px solid rgb(188, 188, 188);
}

.ui-multiselect-close {
    display: none;
}

.ui-icon-triangle-1-s {
    text-align: right;
}

.ms-input input:hover {
    cursor: pointer;
}

.ms-input input {
    height: auto !important;
    min-height: 20px;
    padding: 0 20px 0 5px;
    margin: 0;
    outline: 0;
    border: 1px solid #aaa;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    background: #fff url('../../Images/down.png') no-repeat 100% 0px !important;
}

.datePickerTextBox {
    width: 75px;
}

.info {
    font-weight: bold;
    background-color: #ffffff;
}

.succes-login {
    background-color: #d4edda;
}

.failed-login {
    background-color: #f8d7da;
}











.multiselect {
    width: 95%;
}

.selectBox {
    position: relative;
    margin-left: 4px;
}

    .selectBox select {
        width: 100%;
        margin-left: 0px;
    }

.overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

#checkboxes {
    width: 0px;
    background-color: #ffffff;
    position: fixed;
    z-index: 202;
    margin-left: 4px;
    max-height: 0px;
    overflow: auto;
}

    #checkboxes label {
        width: 100%;
        max-width: 100%;
    }

        #checkboxes label:hover {
            background-color: #1e90ff;
        }

.toggleExpand {
    cursor: pointer;
    text-decoration: underline;
}

.hiddentext {
    display: none;
}

.textareaDescription {
    padding-bottom: 20px;
    font-style: italic;
}

.ui-multiselect-header a:hover {
    color: white !important;
}

.bg-none {
    background-color: #fff !important;
}

.input-search-icon {
    position: relative;
    display: inline-block
}

    .input-search-icon input {
        width: 100%;
        padding-right: 20px !important;
    }

    .input-search-icon:after {
        content: "\f002";
        font-family: FontAwesome;
        position: absolute;
        top: 2px;
        right: 10px;
    }
