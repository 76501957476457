

#MenuSelectionView{

	.selection-active-menu{
		margin-bottom: 1em;
	}

	.LabelClass{
		padding-right:1em;
		&.detail {
			margin-left: 1em;
			color:grey;
		}
	}
    
    .legend{
        padding-top:10px;
    }

    .info_label{
			font-size: 1em;
			margin-top:1em;
			vertical-align:middle;
			display:inline-block;
            margin-right:1em;

			&.today{
				&:before{
					content : "\f073";
					font-family: FontAwesome;
					padding-right:5px;
				}
                span{
                    font-weight:bold;
                }
			}

			&.anyday{
				&:before{
					content : "\f14a";
					font-family: FontAwesome;
					padding-right:5px;
				}
                span{
                    font-weight:bold;
                }
			}
		}
		

		.gPlaced{
				.client{
					a:after{
						font-family: FontAwesome;
						content: "\f073";
						padding-left:5px;
					}
				}
			}
}