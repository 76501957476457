
//== Tabs
.nav-tabs {
    border-bottom-color: @nav-tabs-active-link-hover-border-color;
    margin-top: 15px;

    > li > a {
        &, &:hover {
            background: @brand-info;
        }

        border-color: @nav-tabs-link-hover-border-color;
        color: @nav-tabs-active-link-hover-color;
        border-radius: 0;
        padding: 2px 8px;
        text-align: center;
        min-width: 80px;
    }
}

.nav__hide--blog {
    display: none;
}

.nav__icon--help {
    padding: 0 0 0 0;
}

//== Pills
.nav-pills > li, .nav-pill-item {
    &.disabled > * {
        opacity: 0.3;
    }

    &:hover {
        background-color: darken(@brand-info, 10%);
        border-color: darken(@brand-info, 12%);
        border-radius: @border-radius-small;
    }

    > label {
        margin: 0;
        color: @text-color;
    }

    > label, .btn {
        > img {
            margin-top: -4px;
        }

        padding: 2px 13px 1px;
        cursor: pointer;
        text-align: center;
        line-height: 24px;
        border-top: none;
        border-bottom: none;
    }

    > a {
        padding: 5px 11px;
        color: #375382;
        font-size: 14px;
    }

    border-radius: @border-radius-small;
    background: @brand-info;
}


//== Toolbox
.nav-toolbox {
    background: #375382;
    padding: 8px 18px;
    font-size: 0;
    overflow: hidden;
}

.nav-toolbox-item.nav-toolbox-item {
    height: 25px;
    font-size: @font-size-base;
    line-height: 25px;
    text-align: center;
    margin-right: 1px;
    border-radius: @border-radius-small;
    background: @brand-info;
    float: left;

    .fa {
        font-size: 14px;
        vertical-align: middle;
    }

    > a, > span, > button, label {
        display: inline-block;
        min-width: 40px;
        padding: 3px 10px;
        border-radius: @border-radius-small;
        color: darken(@brand-info, 60%);

        &:hover {
            background: #eee;
        }
    }
}

.nav-toolbox-item.nav-toolbox-item-mashie-icon {
    > a {
        padding-bottom: 0;
        padding-top: 0;
    }

    img {
        vertical-align: middle;
    }
}

.nav-toolbox-item.nav-toolbox-item-danger {
    background: @brand-danger;

    &, > a, > span, > button, label {
        color: darken(@brand-danger, 40%);
    }
}

.nav-toolbox-item.nav-toolbox-item-warning {
    background: @brand-warning;

    &, > a, > span, > button, label {
        color: darken(@brand-warning, 40%);
    }
}

.nav-toolbox-item.nav-toolbox-item-primary {
    background: @brand-primary;

    &, > a, > span, > button, label {
        color: darken(@brand-primary, 40%);
    }
}


.navbar-top {
    margin-bottom: 0;
    min-height: 45px;

    .navbar-brand {
        padding-top: 10px;
        padding-bottom: 5px;
        height: auto;
    }

    .navbar-custom {
        margin-right: 0 !important;
    }

    .navbar-text {
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .navbar-btn {
        margin-top: 6px;
        margin-bottom: 3px;
        margin-left: 3px;
    }
}


.mashie-nav {
    
    padding: 0;

    &.horizontal{
        li{
            display: inline-block;

        }
        
    }

    li {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    button{
        padding : 0;
        background: #e6ebf2;
        width : 3rem;
        margin: 0 1px 0 0;
        height : 3rem;
        font-size : 2rem;
        border-radius: 3px;
        &:before{
            
          
        }
      
    }
}
