.recipe__infopanel {
    padding-left: 20px;
}

.recipe__infopanel__history--icon {
    padding-left: 5px;
    font-size: 16px;
    cursor: pointer;
}

.recipe-information--info {
    border-bottom: 1px solid #eeeeee;
    h4 {
        float: left;
        margin: 0;
        padding: 10px 0 0 15px;
        font-weight: bold;
    }

    div {
        position: relative;
        display: inline-block;
        width: 10%;

        span {
            margin: 6px 0 0 3px;
        }

        label {
            position: absolute;
            text-align: center;
            top: 7px;
            left: 8px;
            font-size: 14px;
            font-weight: bold;
        }
    }

    article {
        padding: 0 0 10px 20px;
        font-size: 14px;
        font-style: italic;
    }
}

.information-recipe--container {
    h3 {
        padding: 5px 5px 5px 15px;
        font-weight: bold;
        margin: 0;
    }

    section {
        padding: 0 0 20px 0px;
        margin-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
    }
}

.recipe-information-piechart--container {
    margin-left:1em;
    h3 {
        font-weight: bold;
        padding: 0 0 0 15px;
    }
}

.recipe-information--h4-removed {
    h4 {
        display: none !important;
    }

    div {
        display: none !important;
    }

    article {
        padding: 0 10px 10px 15px;
        font-size: 14px;
        font-style: italic;
        width: 95%;
    }
}
