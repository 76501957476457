@import "../../mashiecolors.less";

#OrderListHolidays {



    #Days {
        margin-right: 10px;
        float: left;
       


        ul.buttonlist {
            float:left;
            padding:0;

            li {
                list-style: none;
            }
        }

        input[type=button] {
            margin-top: 5px;
            float: right;
        }
    }

    #OrderLists {


        .OrderListItem {


            .OrderListText {
                padding: 3px 10px;
                cursor: default;
            }

            .ListState {
                height: 7px;
                width: 25px;
                cursor: pointer;
                text-align: center;
                background-color: @warninig-red;


                &.activeonholiday {
                    background-color: @ok-green;
                }
            }
        }
    }
}
