@bootstrap-path: "../../../node_modules/bootstrap";
@bootstrap-less: "@{bootstrap-path}/less";

// Core variables and mixins
@import "@{bootstrap-less}/variables.less";
@import "bootstrap-variables.less";
@import "@{bootstrap-less}/mixins.less";

// Reset
@import "../vendor/normalize.less";
@import "@{bootstrap-less}/print.less";

// Core CSS
@import "@{bootstrap-less}/scaffolding.less";
@import "@{bootstrap-less}/type.less";
@import "@{bootstrap-less}/code.less";
@import "@{bootstrap-less}/grid.less";
@import "@{bootstrap-less}/tables.less";
@import "@{bootstrap-less}/forms.less";
@import "@{bootstrap-less}/buttons.less";

// Components
@import "@{bootstrap-less}/component-animations.less";
@import "@{bootstrap-less}/dropdowns.less";
@import "@{bootstrap-less}/button-groups.less";
@import "@{bootstrap-less}/input-groups.less";
@import "@{bootstrap-less}/navs.less";
@import "@{bootstrap-less}/navbar.less";
@import "@{bootstrap-less}/breadcrumbs.less";
@import "@{bootstrap-less}/pagination.less";
@import "@{bootstrap-less}/pager.less";
@import "@{bootstrap-less}/labels.less";
@import "@{bootstrap-less}/badges.less";
@import "@{bootstrap-less}/jumbotron.less";
@import "@{bootstrap-less}/thumbnails.less";
@import "@{bootstrap-less}/alerts.less";
@import "@{bootstrap-less}/progress-bars.less";
@import "@{bootstrap-less}/media.less";
@import "@{bootstrap-less}/list-group.less";
@import "@{bootstrap-less}/panels.less";
@import "@{bootstrap-less}/responsive-embed.less";
@import "@{bootstrap-less}/wells.less";
@import "@{bootstrap-less}/close.less";

// Components w/ JavaScript
@import "@{bootstrap-less}/modals.less";
@import "@{bootstrap-less}/tooltip.less";
@import "@{bootstrap-less}/popovers.less";
@import "@{bootstrap-less}/carousel.less";

// Utility classes
@import "@{bootstrap-less}/utilities.less";
@import "@{bootstrap-less}/responsive-utilities.less";


// Extras
.text-primary { color: @brand-primary; }
.text-success { color: @brand-success; }
.text-info { color: @brand-info; }
.text-warning { color: @brand-warning; }
.text-danger { color: @brand-danger; }
