@mashie-green : #95b417;
@blue : #3f5c8c;
@warninig-red: #fc521b;
@red :#fc1b1b;
@red-lite : #ffe4e4;
@ok-green: #79b516;
@lightblue : #cad0d9;
@blue : #3f5c8c;
@mashie-blue: #375382;
@green-light: #f2ffde;
@legacy-gray: #f1f2f3;
@border-gray: #babfc4;
@legacy-light-blue: #eaedf8;

@blue1 : #c0d8ff;
@unsaved-color: #ede888;
@gray : #eee;
@white-rock : #F0EDDF;
@odd-row: #f8f7f7;
@yellow : #f1ae10;

.alteredrow-colors {
  background-color: @unsaved-color !important;
}
