.orderLocks {
    padding-top: 10px;

    .section {
        border: 1px solid #CFD8DC;
        width: 470px;
        margin-top: 10px;
        margin-bottom: 10px;

        input[type='number'] {
            width: 40px;
        }

        table {
            table-layout: fixed;
            width: 460px;
            text-align: left;
            border-spacing: 0px;
            font-size: 9pt;
            padding-top: 10px;
        }

        th {
            padding: 5px;
            height: 35px;
        }

        tbody {


            td {
                padding: 5px;
                height: 35px;
            }

            tr:nth-child(odd) {
                background-color: #ECEFF1;
            }

            tr .white {
                background-color: white;
            }
        }

        .lockTime {
            padding-left: 10px;
            width: 70px
        }

        .dayOfTheWeek {
            width: 100px
        }

        .days {
            width: 70px
        }

        .time {
            width: 90px
        }

        .lastOrderType {
            padding-left: 10px;
            width: 130px
        }

        .filler {
            height: 15px;
        }
    }

    .checkBox {
        display: inline-block;
        margin-right: 5px;
        vertical-align: middle;
    }

    .accordion {
        display: inline-block;
    }

    .accordion-container {
        padding-top: 25px;

        .name {
            width: 230px;
            display: inline-block;
            padding-left: 27px;
            font-weight: bold;
        }

        .lockTime {
            font-weight: bold;
        }
    }

    .noLockType {
        color: gray;
    }

    .vertical-align {
        position: relative;
        top: -2px;
    }

    .noContent {
        color: gray;
        margin-top: 25px;
    }

    .labelBar {
        background: #ECEFF1;
        cursor: pointer;
        padding-top: 7px;
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right: 10px;
        height: auto;

        i {
            color: #2989AD;
        }

        span {
            display: inline-block;
            width: 45%;
            vertical-align: middle;
        }
    }

    .open {
        .label {
            border-bottom: 1px solid #B0BEC5;
        }
    }

    .closed {
    }

    .container {
        width: 100%;
        padding-right: 0px;
    }

    .titleBar {
        display: flex;
        width: 100%;
        margin-top: 24px;
        margin-bottom: 19px;
    }

    .titleBar-text {
        vertical-align: middle;
        width: 100px;
        font-weight: bold;
    }

    .titleBar-image {
        vertical-align: middle;
        width: 100%;
    }

    .greenTitleBar {
        background-image: url("../../../../../Images/Gradient/greentitlebar.png");
        height: 9px;
        width: 100%;
        border-width: 0px;
        vertical-align: middle;
    }

    .content {
        display: inline-flex;
        width: 100%;

        .data {
            margin-top: 15px
        }
    }

    .instructions {
        width: 330px;
        margin-left: 200px;
        border: 1px solid #B0BEC5;
        border-radius: 5px;
        background-color: #fdffe7;
        padding: 20px;
        height: auto;
        flex-grow: 0;
    }

    .lockButton-Locked {
        background-image: url("../../../../../Images/Toolbar/Gradient/Lock_on.png");
        border: 0;
        width: 37px;
        height: 30px;
        border-radius: 2px;
        cursor: pointer;
        background-position: center;
    }

    .lockButton-Locked:hover {
        background-image: url("../../../../../Images/Toolbar/Gradient/Lock_omo.png");
    }

    .lockButton-UnLocked {
        background-image: url("../../../../../Images/Toolbar/Gradient/Lock_unlocked_on.png");
        border: 0;
        width: 37px;
        height: 30px;
        border-radius: 2px;
        cursor: pointer;
        background-position: center;
    }

    .lockButton-UnLocked:hover {
        background-image: url("../../../../../Images/Toolbar/Gradient/Lock_unlocked_omo.png");
    }

    .actionButton {
        border: 0;
        width: 30px;
        height: 30px;
        border-radius: 2px;
        cursor: pointer;
        background-position: center;
    }

    .newButton-Locked {
        background-image: url("../../../../../Images/LightBlueButtons/dimmadNy.gif");
    }

    .newButton-UnLocked {
        background-image: url("../../../../../Images/LightBlueButtons/orgNy.gif");
    }

    .newButton-UnLocked:hover {
        background-image: url("../../../../../Images/LightBlueButtons/OMONy.gif");
    }

    .deleteButton-Locked {
        background-image: url("../../../../../Images/LightBlueButtons/dimmadTaBort.gif");
    }

    .deleteButton-UnLocked {
        background-image: url("../../../../../Images/LightBlueButtons/orgTaBort.gif");
    }

    .deleteButton-UnLocked:hover {
        background-image: url("../../../../../Images/LightBlueButtons/OMOTaBort.gif");
    }

    .saveButton-Locked {
        background-image: url("../../../../../Images/LightBlueButtons/dimmadSpara.gif");
    }

    .saveButton-UnLocked {
        background-image: url("../../../../../Images/LightBlueButtons/orgSpara.gif");
    }

    .saveButton-UnLocked:hover {
        background-image: url("../../../../../Images/LightBlueButtons/OMOSpara.gif");
    }
}
