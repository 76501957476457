@import "../vendor/clearless/sprites";

.flagsprite {
    .sprite-ir( 0, 0, 13px, 12px, '../../../../Images/flags/allflags2.png' );
    padding:0px;
    &.flagsprite-sv { background-position: -(0 * 14px) -(2 * 11px); }
    &.flagsprite-da { background-position: -(1 * 14px) -(2 * 11px); }
    &.flagsprite-no { background-position: -(2 * 14px) -(2 * 11px); }
    &.flagsprite-en { background-position: -(3 * 14px) -(2 * 11px); }
    &.flagsprite-de { background-position: -(4 * 14px) -(2 * 11px); }
    &.flagsprite-fi { background-position: -(5 * 14px) -(2 * 11px); }
    &.active {
        &.flagsprite-sv { background-position: -(0 * 14px) -(0 * 11px); }
        &.flagsprite-da { background-position: -(1 * 14px) -(0 * 11px); }
        &.flagsprite-no { background-position: -(2 * 14px) -(0 * 11px); }
        &.flagsprite-en { background-position: -(3 * 14px) -(0 * 11px); }
        &.flagsprite-de { background-position: -(4 * 14px) -(0 * 11px); }
        &.flagsprite-fi { background-position: -(5 * 14px) -(0 * 11px); }
    }
 }

.sprite {
    display: inline-block;
    width: 33px; height: 33px;
    vertical-align: top;
    background: url(../../img/sprite.png) top left no-repeat;
    background-position-y: -40px;
  
    &.sprite-admin { background-position: (-2px + -(38px * 0)) -40px; }
    &.sprite-article-connection { background-position: (-2px + -(38px * 1)) -40px; }
    &.sprite-barcode-2 { background-position: (-2px + -(38px * 2)) -40px; }
    &.sprite-basket { background-position: (-2px + -(38px * 3)) -40px; }
    &.sprite-hierarchy { background-position: (-2px + -(38px * 4)) -40px; }
    &.sprite-item { background-position: (-2px + -(38px * 5)) -40px; }
    &.sprite-meal { background-position: (-2px + -(38px * 6)) -40px; }
    &.sprite-menu { background-position: (-2px + -(38px * 7)) -40px; }
    &.sprite-typing { background-position: (-2px + -(38px * 8)) -40px; }
    &.sprite-logbook { background-position: (-2px + -(38px * 9)) -40px; }
    &.sprite-skillet { background-position: (-2px + -(38px * 10)) -40px; }
    &.sprite-purchase { background-position: (-2px + -(38px * 11)) -40px; }
    &.sprite-recipe { background-position: (-2px + -(38px * 12)) -40px; }
    &.sprite-start { background-position: (-2px + -(38px * 13)) -40px; }
    &.active {
        &.sprite-admin { background-position: (-2px + -(38px * 0)) -2px; }
        &.sprite-article-connection { background-position: (-2px + -(38px * 1)) -2px; }
        &.sprite-barcode-2 { background-position: (-2px + -(38px * 2)) -2px; }
        &.sprite-basket { background-position: (-2px + -(38px * 3)) -2px; }
        &.sprite-hierarchy { background-position: (-2px + -(38px * 4)) -2px; }
        &.sprite-item { background-position: (-2px + -(38px * 5)) -2px; }
        &.sprite-meal { background-position: (-2px + -(38px * 6)) -2px; }
        &.sprite-menu { background-position: (-2px + -(38px * 7)) -2px; }
        &.sprite-typing { background-position: (-2px + -(38px * 8)) -2px; }
        &.sprite-logbook { background-position: (-2px + -(38px * 9)) -2px; }
        &.sprite-skillet { background-position: (-2px + -(38px * 10)) -2px; }
        &.sprite-purchase { background-position: (-2px + -(38px * 11)) -2px; }
        &.sprite-recipe { background-position: (-2px + -(38px * 12)) -2px; }
        &.sprite-start { background-position: (-2px + -(38px * 13)) -2px; }
    }
}

.sprite.sprite-logout {
    background-position: 0 -75px;
    width: 21px; height: 21px;
}

.fa-size-md { font-size: 18px; }
.fa-circled { position: relative !important; }

.fa-circled:after {
    content: "";
    position: absolute;
    left: -3px;
    top: -4px;
    border-radius: 250px;
    box-shadow: 0 0 0 1px #AAA;
    padding: 11px;
}
