.alert-bar {
    position: relative;
    display: block;
    width: 100%;
    top: 0;
    left: 0;
    // background-color: #f2dede;
    color: black;
    pointer-events: none;
    background-color: #fffada;
    border: 2px solid coral;
    font-size: 1em;
    color: black;
    padding-left: 10px;
    transition: opacity 2s;

    &:hover {
        user-select: none;
        pointer-events: none;
    }


}
