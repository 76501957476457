.productInfo__container { 
    width: 100%; 
    padding: 10px;
    
    textarea { 
        resize: none; 
        outline: none; 
    }

    .table > tbody > tr > td { border-top: none !important;  }

    .productInfo__container--table { 
        width: 40%; 
        float: left;                                

        .table__label { width: 200px; }
        .table__context { width: 350px; }
        .table__context--name { width: 300px; }
        .table__label--header {text-decoration: underline;}
        .table--link { 
            padding: 0 0 0 15px; 
            font-size: 16px; 
        }
    }

    .productInfo__container--imgContainer { 
        float: left; 
        width: 50%;
        
        .imgContainer--wrapper { 
            width: 200px;  
            padding: 10px 0 0 0;  
        }
    }

    .productInfo-container--allergens {
         width: 559px;
    }
}