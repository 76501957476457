.information-allergens--container {
    margin: 0 0 15px 0;

    h3 {
        margin: 15px 0 5px 0;
        font-weight: bold;
    }

    div {
        width: 100%;
        margin: 0 0 5px 0;
        font-weight: bold;
    }
}

.information-allergens--label {
    margin: 5px 0 0 10px;
    font-style: italic;
    font-size: 13px;
    font-weight: bold;
}

.information-allergens-component--label {
    margin: 5px 0 0 0;
    font-style: italic;
    font-size: 13px;
    font-weight: bold;
}
