
.panel-heading {
    padding: 15px;
}

.panel-body { padding: 15px; }
.panel-body-content {
    padding: 15px;
}

.panel-dashed {
    border: 0;
    box-shadow: none;
    padding: 0 0 12px 0;

    .panel-heading {
        position: relative;
        line-height: 6px;
        border-radius: 0;
        padding: 0;
        top: 8px;
    }

    .panel-title {
        font-size: @font-size-base;
        display: inline;
        background: @body-bg;
        padding-right: 8px;
        line-height: 0;
        font-weight: bold;
    }
}
