/*@keyframes showandhide{
    0% { top: -50px}
    20% { top: 0}
    80% { top: 0}
    100% { top: -50px}
}*/


/*#saveConfirmationBorder {
    position: fixed;
    height: 50px;
    font-size: 1.5rem;
   // font-weight: bold;
    top: -50px;
    left: 0;
    right: 0;
    border-width: 0 0 1px 0;
    border-radius: 0;
    background-color: rgba(255, 255, 200, 0.8);
    border-bottom: 1px solid orange;
    text-align: center;
    vertical-align:middle;
    padding: 10px;
    animation-name: showandhide;
    animation-duration: 2s;
}*/

.modal.mashiemodal1 {

    .modal-content {
        padding: 2px;
        border: 1px solid #c3c3c3;
        border-radius: 0;
        box-shadow: none;

        .modal-header {
            background-color: #103166;
            color: #DEE3E2;

            .modal-title {
                padding: 2px 5px;
            }

            [data-dismiss="modal"] {
                span {
                    color: white;
                }
            }
        }
    }
}

.modal.confirmationdialog {
    .modal-dialog {
       width: 300px;
       top: 20vh;
       left : 10vw;
      

        .modal-content {
            padding: 2px;
            border: 1px solid #c3c3c3;
            border-radius: 0;
            box-shadow: none;

            .modal-header {
                background-color: #103166;
                color: #DEE3E2;

                .modal-title {
                    padding: 2px 5px;
                }

                [data-dismiss="modal"] {
                    span {
                        color: white;
                    }
                }
            }

            .modal-body{
                padding: 15px;
                .buttons{
                    text-align:right;
                }
            }
        }
    }
}
