@import "layout";
@import "navigation";

body.mashie {
    padding: 0;
    overflow-y: scroll;
}

body.mashie label {
    display: block;
}

a {
    cursor: pointer;
}

.fa-sm {
    font-size: 0.75em;
    vertical-align: middle;
}

.fa, .fa:hover {
    color: inherit; text-decoration: none;
}

html.no-js { display: none; }
