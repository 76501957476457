#information-modal {
    position: relative;
    transform: translate3d(0, 0, 0);

    @media only screen and (min-width: 481px) {
        width: 100% !important;
        margin: 0;
    }

    @media only screen and (min-width: 578px) {
        width: 90% !important;
        margin: 30px auto;
    }

    @media only screen and (min-width: 768px) {
        width: 85% !important;
        margin: 30px auto;
    }

    @media only screen and (min-width: 992px) {
        width: 65% !important;
        margin: 30px auto;
    }

    @media(min-width : 1200) {
        width: 50% !important;
        margin: 30px auto;
    }
}

.information-modal--close {
    opacity: 1;
    color: white;
    font-size: 20px;
    padding: 5px !important;
    background: transparent;
}

.information-modal-header--label {
    height: 0;
    font-size: 22px;
    padding: 0 0 0 0;
    font-weight: bold !important;
}


.information--header {
    height: 35px;
    background: #eeeeee;

    h3 {
        margin: 0;
        line-height: 25px;
        padding: 5px 5px 5px 15px;
        font-weight: bold;
    }
}


.information--right {
    padding: 0 0 0 25px;
    width: 49%;
}

#information-modal .information--left {
    z-index: 999;
    text-shadow: 1px 0.5px 0px white;
}
.information--left {
    padding: 0;
}

.information-image--container {
    
    border-bottom: 1px solid #eeeeee;
    text-align: center;
    margin: 0 0 0 0;
    padding: 0 0 25px 0;

    img {
        max-width:150px;
    }
}

.information-icon {
    font-style: normal;
}
