.modal-body .inventory-export__dropdown {
  display: flex;
  width: 300px;
  margin-bottom: 0.5em;
  label {
    flex-basis: 125px;
    font-weight: bold;
    flex-shrink: 0;
    align-self: center;
  }
  select {
    flex-grow: 4;
  }
}
.modal-body .inventory-export__search {
  display: flex;
  width: 100%;
  margin-bottom: 1.5em;
  label {
    font-weight: bold;
    flex-basis: 125px;
    flex-shrink: 0;
    align-self: center;
  }
  input {
    flex-grow: 4;
  }
}
