@import "../mashiecolors.less";

@cellpaddingHead: 1px 5px;
@cellpadding: 1px 5px;
@cellpaddingInput: 5px;
@cellpaddingSelect: 5px 0;
@cellBorderRadius: 4px;

@keyframes newRow {
    0% {
        background-color: inherit;
    }

    50% {
        background-color: @mashie-green;
        opacity: 0.5;
    }

    100% {
        background-color: inherit;
    }
}

.table-inputs {
    input, select {
        margin: 2px 0;
        background-color: white;
        border-radius: @cellBorderRadius;
    }

    select {

        &:disabled {
            background-color: lightgrey;
            color: gray;
        }

        &:invalid {
            outline: 2px dotted #ddd;
        }

        margin: 2px 0;
        border: 2px solid transparent;
        padding: @cellpaddingSelect;
        min-width: 100%;
        background-color: transparent; //inherit funkade för chrome men IE krävde transparent
        &:not(:disabled):hover {
            cursor: default;
            border: 2px solid @lightblue;
            border-radius: @cellBorderRadius;
        }

        &:not(:disabled):active, &:not(:disabled):focus {
            border: 2px solid @blue;
            border-radius: 5px;
            outline: none;
        }
    }

    input[type="checkbox"], button { //checkboxes and button
        margin: @cellpadding !important;
    }

    input:not([type]), input[type="text"] { //textboxes
        &:not(:disabled):hover {
            cursor: default;
            border: 2px solid darken(@lightblue,10%);
            border-radius: @cellBorderRadius;
        }

        &:disabled {
            background-color: lightgrey;
            color: gray;
        }

        &:invalid {
            outline: 2px dotted #ddd;
        }

        &::selection {
            background-color: @blue;
            color: white;
        }

        &:not(:disabled):focus, &:not(:disabled):active {
            &:hover {
                cursor: text;
            }

            outline: none;
            border: 2px solid @blue;
            border-radius: 5px;
        }

        border: 2px solid @lightblue;
        padding: @cellpaddingInput;
        width: 100%;
        background-color: transparent; //inherit funkade för chrome men IE krävde transparent
    }

    input[type=checkbox] {
        height: 0.7vw;
        width: 0.7vw;
        min-height: 13px;
        min-width: 13px;
        border-radius: 10px;
    }
}

.tablehead {
    thead {
        user-select: none;
        cursor: default;

        td {
            padding: @cellpaddingHead;
            font-size: 1.3rem;
            border-top: 1px solid @gray;
            border-bottom: 1px solid @gray;
            text-align: left;
            white-space: nowrap;
        }

        tr {
            height: 30px;
            font-weight: bold;
            color: Black;
        }
    }
}

.tableBody {
    tbody {
        tr {
            &:last-of-type {
                td {
                    border-bottom: 1px solid @gray;
                }
            }
            /*&:has( input.focus ) {
                    //kommer kanske i CSS 4.
                    // här kan editMode tryckas in.
                }*/
            td {
                label {
                    padding: @cellpadding;
                    font-weight: normal;
                }

                padding: 2px;
                //  border: 1px solid @gray;
            }

            &:nth-child(odd) {
                background-color: #f8f7f7;
            }
        }
    }
}

.tablebase {
    .table-inputs;
    .tablehead;
    .tableBody;
    border-collapse: collapse;
    width: 100%;



    tr { // thead
        vertical-align: middle;
        font-weight: normal;
        font-size: 9pt;
        font-family: Arial;
        color: Black;
    }

    td, th { //alla
        &:first-of-type {
            border-left: 1px solid @gray;
        }

        &:last-of-type {
            border-right: 1px solid @gray;
        }

        vertical-align: middle;

        &.center {
            text-align: center;

            input, label {
                text-align: center;
            }
        }

        &.left {
            input, label {
                text-align: left;
            }

            text-align: left;
        }

        &.right {
            input, label {
                text-align: right;
            }

            text-align: right;
        }
    }
}
