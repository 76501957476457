@import "tablebase.less";
@import "../animations.less";



.td-compact {
    width: 1px;
    white-space: nowrap;
}

.td-nowrap {
    white-space: nowrap;
}

table .fa:before {
    vertical-align: middle;
}

.td-buttons {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    line-height: 30px !important;
}

.td-buttons > .btn {
    margin-top: -3px;
    margin-bottom: -3px;
    margin-right: 10px;
    padding-left: 12px;
    padding-right: 12px;

    &:last-child {
        margin-right: 0px;
    }
}

td.table-cell-is-deleted {
    opacity: 0.3;
}

td.table-cell-vertical-middle {
    vertical-align: middle !important;
}

td.table-cell-horizontal-center {
    text-align: center !important;
}

.table-dataview thead th {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

tr[js-table--row-selector] .table-selectable-row {
    pointer-events: none;
}

[js-dataview-sort--key] {
    padding-right: 16px !important;
    position: relative !important;
    white-space: nowrap;
    cursor: pointer;

    > .fa-sort {
        color: #AAA;
    }

    > .sort {
        position: absolute;
        right: 8px;
    }
}


.mashie-table {
    .tablebase;
    

    &.table-selectable {
        cursor: pointer; 
    }


    &.mashie-table-hover {

        tbody > tr {
            -moz-transition: ease-in-out 0.5s;
            -o-transition: ease-in-out 0.5s;
            -webkit-transition: ease-in-out 0.5s;
            transition: ease-in-out 0.5s;

            &:hover {
                background-color: #d4e1c9;
                -moz-transition: ease-in-out 0.5s;
                -o-transition: ease-in-out 0.5s;
                -webkit-transition: ease-in-out 0.5s;
                transition: ease-in-out 0.5s;
            }
        }
    }

    tr {
        &.altered {
            .alteredrow-colors;
        }

        &.newrow {
            -moz-animation-name: newRow;
            -o-animation-name: newRow;
            -webkit-animation-name: newRow;
            animation-name: newRow;
            -moz-animation-duration: 2s;
            -o-animation-duration: 2s;
            -webkit-animation-duration: 2s;
            animation-duration: 2s;
        }
    }


    tbody {

        tr {
            .animation-blink{
                 animation : blink 0.5s ease-in-out; 
            }
           
            td {
                height: 35px;
                border-radius: 0;
            }

            &.editMode {
                background-color: @blue !important;
                color: white;

                input, select {
                    background-color: white;
                    border-radius: @cellBorderRadius;
                    border: 2px solid @blue;
                    border-radius: 5px;
                    outline: none;
                }

                select {
                    padding: @cellpaddingSelect;
                    border: 2px solid @blue;
                }

                input {
                    padding: @cellpaddingInput;

                    &::-ms-clear {
                        display: none;
                    }

                    &:hover {
                        // border: 1px solid transparent;
                        padding: @cellpaddingInput;
                    }
                }

                button, label {
                    color: white;
                }
            }



            &.selected {
                background-color: #f5fbff !important;
                font-weight: bold;
            }

            &:not(.selected) {
                td {
                    &.alert {
                        color: red;
                    }
                }
            }
        }
    }
}


table.mashie-table-elements {
    border-collapse: collapse;
    width: 100%;

    td, th {
        padding: 1px 5px;

        select, select:focus {
            padding: 5px;
            font-size: 1em;
            font-family: arial;
            border: none !important;
            outline: none;
            margin: 0;
        }

        select:focus {
            background-color: #Eee;
        }

        select:active {
            //  outline : 2px solid @mashie-green;
        }

        select:hover {
            cursor: pointer;
        }

        width: auto;
        table-layout: fixed;
        border: 1px solid #eaeaea;
    }

    td:last-of-type {
        min-width: 70px;
        border: none;
    }
}



