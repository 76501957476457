.information-piechart--container {
    .chart-legend {
        margin-top: 1em;

        ul {
            list-style: none;
            display: flex;
            flex-direction: column;

            li {
                display: flex;
                align-items: center;
                padding: .25em;
                width: 30em;

                .legend-color {
                    width: 1.75em;
                    height: 1.75em;
                    border-radius: 1px;
                    margin-right: 1em;
                }

                .legend-description {
                    display: flex;

                    .ratio {
                        font-weight: bold;
                        color: #24221F;
                    }
                }
            }
        }
    }
}
