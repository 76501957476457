/**
*  The .DataGridClass class is in use in several places to style tables
*
*  This class is for temporarily styling these tables until all Mashie Tables are updated
*  with a more semantic and centralized style set.  Remove this class when .DataGridClass is no
*  longer necessary.
*
*  @Author: Chris Shields 2016-10-04
*
*/






.DataGridClass td:not(:last-child) {
  white-space: normal;
}

.DataGridClass tr.even, .DataGridClass tr th{
  background-color: #fff;
}

// Attempt to resolve some of the areas where the table contents extend outside of their containers and
// where some of the cells contents are compressed out of view.

.DataGridClass tbody tr td:not(:first-child){
  min-width:7em;
  overflow:hidden;
}

// Disables row clicking in Beställningar page under the Måltidsbeställning tab on the dgOrder table.
// Ideally there would be a simply way to disable row clicks with the DataGrid object, but this was not apparent.
// If the structure of the table changes or an easy to to remove the doPostBack methods from the anchor tags added
// to each row then these rules can be removed.
.DataGridClass.noClick a{
  pointer-events: none;
  cursor: default;
}

// Reenables pointer for the checkboxes to maintain user-friendly design.
.DataGridClass.noClick input[type="checkbox"]{
  cursor: pointer;
}

.kitchOrderPageTable tbody tr td:not(:first-child){
  min-width:0 !important;
  overflow:hidden;
}


.DataGridClass .hiddenInformation{
	min-width:0 !important;
	display:none;
}

.DataGridClass.customOrderStyle{
	td{
		padding: 0 10px;
	}
}