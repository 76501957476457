.advSearchBox {
    /*float: left;*/
    /*width: 304px;*/
    /*margin-right: 10px;*/
}

.advSearchBoxSecondRow {
    margin-top: 6px;
}

.advSearchBox input[type="checkbox"]:first-child {
    /*margin-top: 22px;*/
}

.checkboxes {
    padding-top: 14px;
}

.divSearchCriteriaContainer {
    display: none;
}

.advSearchAltContainer {
    /*margin-bottom: 1px;
            margin-top: 4px;
            height: 20px;*/
}

.advSearchAltLabelContainer {
    /*float: left;*/
    /*display: inline-block;*/
    /*width: 100px;
            margin-top: 3px;*/
}

.advSearchAltSelectionContainer {
}


.advSearchAlt {
    width: 193px;
    height: 20px;
}

.advSearchAltTextBox {
    /*width: 190px;*/
}

.SearchControlClass {
    background-image: url("../../../../../Images/Gradient/searchbar.png");
    padding-left: 20px;
    background-repeat: repeat-x;
    background-position: 0 top;
    background-size: contain;
}

.chkSearchControl {
    float: left;
    padding-left: 15px;
}


.searchResult {
    padding-top: 10px;
    overflow: auto; 
    width: 750px; 
    height: 300px
}