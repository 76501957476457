.inventory-export__results {
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  margin-top: 0.5em;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: scroll;
    max-height: 20em;

    li {
      display: flex !important;
      align-items: center;
      height: 3em;
      cursor: pointer;
      background-color: white;
      transition: background-color 0.2s ease-out;

      &:nth-child(odd) {
        background-color: @odd-row;
      }

      &:nth-child(even) {
        background-color: white;
      }

      &:hover {
        background-color: #cad0d9;
      }

      input[type="checkbox"] {
        margin: 0;
        position: static;
        margin-left: 1em;
      }

      span,
      input[type="checkbox"] {
        margin-right: 1em;
        vertical-align: middle;
      }
    }
  }
}
