.information-portion-instruction--container {
    background: #eeeeee;
    padding: 10px;
    margin-top: 2px;
    border-bottom: 1px solid #eeeeee !important;

    select {
        width: 200px;
        color: white;
        background: #375382;
        border: none;
        padding: 0;
        box-shadow: none;

        &:hover {
            cursor: pointer;
        }

        option {
            color: white;
            background: #375382;

            &:hover {
                cursor: pointer;
            }
        }
    }

    button {
        float: right;
        margin: -25px 0 0 0;
        border: 0;
        height: 31px;
        color: #fff;
        background: #95b417;
    }
}

.information-portioning-instruction--checkbox {
    font-weight: bold;
    margin: 0;
    width: 50%;
    padding: 0 0 0 5px;
}

.information-portioning-instruction--serving-size--container {
    padding-bottom: 5px;
}

.information-portion-instruction--header {
    h3 {
        padding: 5px 5px 5px 0;
        margin: 0 0 10px 0;
        font-weight: bold;
    }
}

.information-portioning-instruction--container {
    font-weight: bold;
    margin: 0 0 10px 5px;
}
