@blue: #315486;

#notifications-root {
  .divider {
    border-bottom: 1px solid #cccbcb;
    width: 100%;
  }

  .note-setting {
    &:hover {
      background-color: rgb(246, 246, 246);
    }
  }

  .header {
    user-select: none;
    padding: 15px 0px;
    margin-right: 20px;
    margin-left: 15px;
    .header-bell-icon {
      margin: 15px;
    }
  }

  .loadMoreButton{
    text-align: center;
    background-color: @blue;
    color: white;    
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .closeButton {
    font-size: 2em;
    font-weight: bold;

    cursor: pointer;
  }

  .headerMenu {
    color: @blue;
  }
}
