
@bordercolor: #e5e5e5;

@keyframes slider {
    0% {
        height: 0 !important;
    }

    100% {
        height: 100% !important;
    }
}

[class*=col] {
    /*border: 1px solid blue;*/
}

[class=row] {
    /*border: 1px solid green;*/
}

#MaintenancePage {

    .hidden {
        display: none;
    }

    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    button {
        padding: 0;
        margin: 0;
        margin-left: 5px;
    }

    .run:not([disabled]) {
        color: #ad7000;

        &:hover {
            color: red;
        }
    }

    .dry-run:not([disabled]) {
        color: blue;

        &:hover {
            color: green;
        }
    }

    .jobsettingsWrapper {

        &.expanded {
        }

        .jobsettings {
            padding: 15px;
            border: 1px solid #eee;
        }
    }


    .jobGroupHeader {
        cursor: pointer;
        background-color: #eee;
        border: 1px solid lightgray;
        padding: 10px;
    }


    .jobs {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.4s;

        &.expanded {
            max-height: 80vh;
            overflow-y: auto;
        }
        //ide dölj alla jobb när en är vald
        &.collapsed {
        }

        .job {
            cursor: pointer;
            border: 1px solid @bordercolor;
            padding: 10px;

            &.selected {
                background-color: #e1f1e1;
            }
        }
    }
}


@height: 20vh;
@width: 100%;

.multiselect {

    .unselected-header {
    }

    .selected-header {
        #filterUnselected {
            width: @width;
        }

        label {
            margin-left: 6px;
        }
    }


    .multiselect-body {
        
        display: flex;
        flex-direction: row;
        min-width: @width;

        .options-column {
            flex-grow: 1;
            flex-basis: 0;


            .options {
                margin: 6px 0 6px 0;
                border: 1px solid #D4D4D4;

                .options-inner {
                    height: @height;
                    overflow-y: auto;
                    width: 100%;
                }
            }
        }

        .selected-options-column {
            flex-grow: 1;
            flex-basis: 0;
            padding-top: 27px;

            .selected-option {
                margin: 0 0 6px 6px;
                cursor: default;
                border: 1px solid #D4D4D4;

                .selected-option-inner {
                    overflow-y: auto;
                    height: @height;
                    width: 100%;
                }
            }
        }
    }

    .multiselectitem {
        cursor: default;

        .multiselectitem-inner {
            padding-left: 10px;
        }

        &:hover {
            background-color: blue;
            color: white;
        }
    }
}
