.item_link_search_modal {
    padding-top: 5%;
}

.item_link_search_modal_header {
    color: #375382;
    height: 45px !important;
    padding: 10px !important;

    i {
        color: white;
        float: right;
        padding: 3px;
    }

    i:hover {
        color: #95b417;
        cursor: pointer;
    }

    h4 {
        color: white;
    }
}


.item-link-spinner {

    .fa:before {
        font-size: 40px;
    }
    i {
        color: #95b417;
    }

    padding: 20% 0 0 50%;
}

.item-link-search-result-container {
    background-color: #eeeeee;
}

#item-link-add-input {
    background: #eeeeee;
    border: none;
    font-size: 13px;
    margin: 0 0 0 -5px;
}

#item-link-add-input:focus {
    border: 1px solid #375382;
}

.btn-disabled, .btn-disabled:hover {
    background-color: #E9E9E9 !important;

    i {
        color: #A3AEBF !important;
    }
}

.item-link-search-container {
    width: 100%;
    padding: 15px;
    height: 60px;

    input {
        padding: 0 0 0 5px;
        width: 82%;
        height: 30px;
    }

    #item-link-search-button {
        border: 1px solid #a9a9a9;
        border-radius: 3px;
        width: 100px;
        height: 30px;
        float: right;
        background-color: #E6EBF2;
    }

    #item-link-search-button:hover {
        cursor: pointer;
    }

    i {
        line-height: 28px;
        color: #3F5C8C;
        text-align: center;
        width: 100%;
    }

    label {
        font-size: 14px;
        margin: 0 0 0 2px;
    }
}

.item_search_selected_Item {
    background-color: #95b417;
    color: white;
}


.item_link_search_header {
    tr {
        border-top: 1px solid #eeeeee;

        .item-link-search_header_right {
            text-align: right;
            padding-right: 25px;
        }
    }
}

.item_link_search_result_text {
    font-size: 13px;
    font-weight: bold !important;
}

.item_link_search_result_text-hits {
    font-size: 13px;
    color: #E12334;
    font-weight: bold !important;
}

.item-link-search-result-label {
    font-size: 13px;
    padding: 13px 0 13px 16px;
    font-weight: bold !important;
}

.item_link_search_result {

    tr:hover {
        cursor: pointer;
    }

    .search_result_item_left {
        padding-left: 18px;
        width: 100%;
    }

    .search_result_item_right {
        text-align: left;
        width: 20px;
    }
}

.item-link-add-modal-button {
    background-color: #eeeeee;
    width: 100px;
    float: left;
    color: black;
    margin: 0 0 0 60%;
}

.item-link-cancel-modal-button {
    background-color: #eeeeee;
    width: 100px;
    float: right;
    margin: 0 2% 0 0 !important;
    color: black;
}

.item-link-remove-modal {
    padding-top: 15%;
    width: 270px;
}

.item-link-remove-header {
    color: #375382;
    height: 45px !important;
    padding: 10px !important;

    i {
        color: white;
        float: right;
        padding: 3px;
    }

    h4 {
        color: white;
    }
}

.item-link-remove-button-confirm {
    background-color: #eeeeee;
    width: 80px;
    float: left;
    color: black;
    margin: 0 0 0 30px;
}

.item-link-remove-button-cancel {
    background-color: #eeeeee;
    width: 80px;
    float: left;
    color: black;
}

.btn_item_add_link {
    border: none;
    margin: -3px 0 0 1px;
    border-radius: 3px;
    width: 30px;
    height: 25px;
    float: left;
    background-color: #E6EBF2;

    i {
        line-height: 25px;
        color: #3F5C8C;
        text-align: center;
        width: 100%;
    }
}

.btn_item_add_link:hover {
    background-color: white;

    i {
        color: #3F5C8C;
    }
}

.btn_item_remove_link {
    border: none;
    border-radius: 3px;
    width: 30px;
    height: 25px;
    float: right;
    margin: -3px 0 0 1px;
    background-color: #E6EBF2;

    i {
        line-height: 25px;
        color: #3F5C8C;
        text-align: center;
        width: 100%;
    }
}

.btn_item_remove_link:hover {
    background-color: white;

    i {
        color: #3F5C8C;
    }
}

.item-link-actionlink-container {
    margin: 3px 0 0 4px;
    border: 1px solid lightgrey;
    width: 235px;
    overflow: hidden;
    height: 36px;
    float: left;
}

.item-previous-actionlink {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    padding: 2px;
    color: #375382;
    float: left;
    padding: 0 0 0 0 !important;
}

.item-previous-actionlink-container {
    width: 21px;
    height: 20px;
    background-color: #eeeeee;
    float: left;
    border-right: 1px solid #A3AEBF;

    i {
        padding: 0 0 0 2px;
    }
}

.item-previous-actionlink-label {
    padding: 0 0 0 5px;
    line-height: 20px;
}

.item-previous-actionlink-label:hover {
    cursor: pointer;
}

.item-link-actionlink {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    padding: 2px;
    color: #375382;
}

.item-link-previous-label {
    padding: 0 0 0 5px;
    line-height: 18px;
    float: left;
}

.item-link-btn-container {
    float: right;
    margin-top: 3px;
}
