@import (less) "../../vendor/jquery.gridster.css";

.dashboard-body #main:after {
    display: none;
}

.dashboard-body #main {
    background: @gray-lighter;
    box-shadow: none;
    margin-top: -10px;
}

.dashboard-navbar {
    padding-left: 20px;
}

.html-widget {
    padding: 10px !important;
}

.ie8 .panel-body {
    margin-top: 50px;
}

.ie8-lock {
    float: left;
}

.ie8 .ie8-lock {
    display: none;
}

.ie8 .ie8-container {
    float: right;

    .fa {
        padding: 5px;
    }
}

.ie8 .navbar-right {
    margin: 10px 0 0 0;
    float: right;
}

.ie8 .navbar-header {
    float: left;
}


.ie8 .dashboard-navbar {
    display: none;
}

.ie-show {
    display: none;
}

.ie8 .ie-show {
    font-size: 14px;
    font-style: italic;
    display: inline-block;
    padding: 10px;
}

.ie8 .ie-hide {
    display: none;
}

// make all dashboard items full width on tablets and phones
//@media screen and (max-width: @screen-sm-min) {
//    .dashboard-toolbox { display: none; }
//    .dashboard { margin-left: 15px; }
//    .dashboard.ready > .grid > .gs-w {
//        float: none !important;
//        width: auto !important;
//        position: relative !important;
//        top: 0 !important; left: 0 !important;
//    }
//}


// stop transitions while resizing
.dashboard:not(.dimensions-ready) > .grid, .dashboard:not(.dimensions-ready) > .grid > .gs-w {
    transition: none !important;
}

.dashboard-editing > .grid > .gs-w {
    // iframes mess up resizing in IE.
    // placing a transparent block above the widget body makes sure you can't interact with it.
    > .panel-body:after {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .dashboard-controls {
        display: inline-block;
    }

    &:hover {
        cursor: move;
        cursor: -webkit-grab;
        cursor: -moz-grab;
    }

    &.resizing {
        cursor: se-resize;
    }

    &.dragging {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        cursor: move;
        cursor: -webkit-grabbing;
        cursor: -moz-grabbing;
    }

    > .gs-resize-handle {
        padding: 16px;
        background-position: center;
        border-radius: 200px;
        border: 2px solid transparent;
        background-color: white;
        border-color: #eeeeee;
    }
}

.dashboard > .grid > .gs-w {
    > .panel-body { padding: 0; }
}

.dashboard > .grid > .gs-w {
    > .panel-body, > .table {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
    }

    > .panel-heading:not(.hidden) {
        & ~ .panel-body {
            top: 57px;
        }

        min-height: 57px;
        line-height: 27px;
        z-index: 1;
        position: relative;
    }
}

.dashboard-controls:extend(.panel-heading) {
    position: absolute;
    right: 0;
    top: 0;
    line-height: 26px;
    display: none;
    z-index: 100;
    background: #ffffff;

    > * {
        margin-left: 8px;

        &, &:hover {
            color: @text-color;
        }
    }

    a:hover {
        text-decoration: none;
        cursor: pointer;
    }
}

.dashboard-widget-iframe {
    padding: 0;

    > iframe {
        position: absolute;
        height: 100%;
        width: 100%;
        border: 0;
    }
}

.dashboard-reading > .grid > .dashboard-widget-image > .panel-body {
    overflow: hidden;
}

.dashboard-reading .editing-control {
    display: none;
}

.dashboard > .grid {
    padding: 0;
}

.dashboard-wait {
    .dashboard.dashboard-loaded & {
        opacity: 0.1;
    }

    &, & * {
        line-height: 80px;
    }

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80px;
    width: 80px;
    text-align: center;
    margin: auto;
    opacity: 0;
    transition: all 100ms 500ms;
    -webkit-transition: all 100ms 500ms;
}

.widget-organization-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 100%;
    margin: auto;
}

.dashboard-widget-image {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 0;
    background: none;

    img {
        max-width: 100%;
        margin: auto;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}

.widget-hantera {
    .panel-heading {
        display: flex;
        justify-content: space-between;

        img {
            vertical-align: middle;
            height: 24px;
        }
    }

    .panel-body {
        padding-top: 20px;
        display: flex;
        flex-direction: column;

        .widget-container {
            flex: 1 1 auto;
            text-align: center;
            width: 100%;
        }

        .kpi-title {
            font-size: 1.6em;
            color: #767676;
        }

        .kpi-answer {
            font-size: 2.4em;
        }

        .kpi-goal {
            margin-top: 10px;
        }

        .kpi-goal-corners {
            font-size: 1em;
            border-radius: 15%;
            padding: 3px;
            border: 1px solid rgb(221, 221, 221);
        }

        .kpi-neutral {
            text-align: center;
            font-size: 1.3em;
            color: black;
        }


        .kpi-negative {
            text-align: center;
            font-size: 1.3em;
            color: red;
        }

        .kpi-positive {
            text-align: center;
            font-size: 1.3em;
            color: green;
        }

        .kpi-row {
            // border-bottom: 1px solid #eee;
            // border-top: 1px solid #eee;
        }

        .kpi {
            height: 100%;
            padding: 15px;
            min-height: 150px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .kpi:not(:last-child) {
            border-right: 1px solid #ddd;
        }
    }

    .panel-body-footer {
        padding: 10px 15px;
        border-top: 1px solid #ddd;
    }
}
