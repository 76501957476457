.information-article--container {
    h3 {
        font-weight: bold;
        margin: 0 0 5px 0;
    }
}

.information-article--container--table {
    width: 49%;
    float: left;
}

.information-article--container--left {
    margin: 0 0 0 20px;
}
