html {
    .ui-tabs {
        border: 0 !important;
        padding: 0 !important;

        .ui-tabs-nav {
            padding-left: 17px !important;
            border-bottom: 1px solid darken(#f1f2f3, 20%) !important;
            background: #f1f2f3 !important;
        }

        .ui-tabs-anchor {
            outline: none;
        }
    }

    .ui-widget-header:not(.ui-dialog-titlebar) {
        border: none;

        .ui-state-active {
            .PageLabelClass {
                /*color: #f1f2f3 !important;*/
                background-color:white !important;
            }
        }
    }

    .ui-state-active a, .ui-state-active a:link, .ui-state-active a:visited {
        color: #000;
    }

    .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
        background: #fff;
        border-color: darken(#f1f2f3, 20%);
    }
}
