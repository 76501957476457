
.meal-information-meal--container {
    width:95%;
}

.meal-information--multiple {
    border-bottom: 1px solid #eeeeee;

    h4 {
        float: left;
        margin: 0;
        padding: 10px 0 8px 0;
        font-weight: bold;
    }

    section {
        position: relative;
        display: inline-block;
        width: 10%;

        span {
            margin: 6px 0 0 3px;
        }

        label {
            position: absolute;
            text-align: center;
            top: 7px;
            left: 7px;
            font-size: 14px;
            font-weight: bold;
        }
    }

    article {
        padding: 0 0 10px 20px;
    }
}

.meal-information--info {
    border-bottom: 1px solid #eeeeee;
    padding: 0 0 15px 0;

    h3 {
        padding: 5px 5px 0 15px;
        font-weight: bold;
        margin: 0;
    }

    article {
        padding: 10px 0 0 20px;
    }
}


// dessa ligger på fel ställe eller tillhör det gamla
.meal__infopanel {
    #ctl00_ctl00_MainBodyPlaceholder_ContentPlaceholder_GroupsControl1_updatePanel {
        margin-left: 25px;
    }
}

.meal__infopanel__textbox {
    margin-left: 25px;
}

.meal__infopanel__history--icon {
    padding-left: 5px;
    cursor: pointer;
    font-size: 16px;
}

.meal__infopanel__link--lastused {
    padding: 0;
    margin-left: 26px;
}

.meal__infopanel__groupcontrol {
    margin-left: 25px !important;
}

.meal__infopanel__webpublish-span {
    margin-left: 23px;
}
