@import "mashiecolors.less";

ul.mashie-list{
    list-style :none;
    padding: 0 5px;
    
  

    li{
        text-align: right;
        text-decoration:none;
        padding: 20px 10px;
        margin: 3px 0;
        background-color: @blue;
        color:@white-rock;
        

    }

}