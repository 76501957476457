@import "../mashiecolors.less";

.tabs__nav {
  border: none;
  background-color: @legacy-gray;
  border-bottom: 1px solid @border-gray;
  padding: 0.2em 0.2em 0;
  padding-left: 17px;
}

.tab__content--item {
  width: 250px;
  font-weight: bold;
  padding: 0;
  padding-bottom: 1px;
  margin: 1px 0.2em -1px 0;
  display: inline-block;
  border: 1px solid @border-gray;
  list-style: none;
  cursor: pointer;
  background-color: @legacy-light-blue;

  div {
    padding: 0.5em 1em;
  }

  &:hover {
    background-color: @legacy-gray;
  }
}

.tab__content--item.active {
  border-bottom-color: white;
  background-color: white;
}

.tab__locationandcodes {
  padding: 1em 1.4em;

  button {
    margin: 0.5em 0;
    color: black;
    text-decoration: none;
    position: relative;
    background-color: #e6ebf2;
    border: 1px solid #7f9db9;
    border-radius: 3px;
    padding: 2px 9px;

    &:hover {
      background-color: white;
    }
  }
}

.isOpenModal {
  display: block;
}
