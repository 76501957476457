@import "../../mashiecolors.less";

#fetchCustomOrderModal{
    


	#customerClients{
		height:100%;
        width:100%;
        overflow-x:hidden;
        overflow-y:auto;


		.info_label{
			font-size: 0.8em;
			margin-top:1em;
			vertical-align:middle;
			display:block;

			&.today{
				&:before{
					content : "\f073";
					font-family: FontAwesome;
					padding-right:5px;
				}
			}

			&.anyday{
				&:before{
					content : "\f14a";
					font-family: FontAwesome;
					padding-right:5px;
				}
			}
		}
		

		.gPlaced{
				.client{
					a:after{
						font-family: FontAwesome;
						content: "\f073";
						padding-left:5px;
					}
				}
				&.placed{
					.client{
						a:after{
							font-family: FontAwesome;
							content: "\f073 \00a0  \f14a";
							padding-left:5px;
						}
					}
				}
			}
	

		.client{
			a{
				color:grey;
				line-height:2em;
				color:black;
			}
		}

		.client-selected{
			background-color: @blue;
			a{
				color:white;
			}
		}

		.inactive-client{
			a{
				color:grey;
                text-decoration: line-through;
			}
		}
	}

}