@import "../../mashiecolors.less";


#customerOptions{
		/*overflow-y: auto;
		overflow-x:hidden;*/
		width:100%;
		height:100%;
		table-layout:fixed;
		border-collapse:collapse;
		width:100%;
                                overflow-x:hidden;
                                overflow-y:auto;

	table{
		th{
			&.options-row_meal_amount_input{
				width:60px;
			}

			&.options-row_date-label{
				width:160px;
			}

			&.options-row_meal_select{
				width:auto;
			}
		}


		tr{
			&.selected{
					background-color: @blue;
					color:white;
					&.inactive{
						background-color:inherit;
						color:black;
						&:nth-of-type(odd){
							background-color:#f9f9f9;
						}
					}
				}
		}

		td{
			position:relative;
			
			select{
                width:100%;
				max-width:100%;
				height:2em;
			}

			&.options-row_meal_amount_input{
				width:60px;
				max-width:50px;
				input{
					height:2em;
					width:100%;
					margin:0;
					padding:0;
				}
			}

			&.options-row_date-label{
                white-space:nowrap;
				width:auto;
				background-color:inherit;
				span{
					line-height:2em;
				}
				
			}

			
		}
	}
}