.inventory-export__tab-content {
  .inventory-export__chosen-values {
    display: inline-block;
    span {
      display: inline-block;
      margin-left: 1em;
      background-color: lightgrey;
      color: green;
      padding: 2px 8px;
      border-radius: 15px;
    }
  }
}
