@import "mashiecolors.less";


.status-circle-red {
    .status-circle;
    background-color: @red;
}

.status-circle-green {
    .status-circle;
    background-color: @mashie-green;
}

.status-circle-yellow {
    .status-circle;
    background-color: @yellow;
}


.status-circle {
    display: block;
    position: relative; 
    width: 14px;
    height: 14px;
    background-color: red;
    vertical-align : middle;
    border-radius: 100%;
}

.mashie-fieldset {
    border-radius: 5px;
    border: 1px solid black !important;
    margin: 0 !important;
    margin-bottom: 20px !important;

    &:hover {
        border: 2px solid black !important;
        margin: -1px -1px 19px -1px !important;
    }
}