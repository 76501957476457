
.information-nutrient--container {
    h3 {
        margin: 0 0 5px 0;
        font-weight: bold;
    }
}

.information-nutrient--container--table {
    width: 49%;
    float: left;
}

.information-nutrient--container--left {
    margin: 0 0 0 20px;
}
