.recycle-bin {
    background-color: white;
    margin: 0 20px;

    &:after {
        content: '';
        position: absolute;
        background-color: white;
        right: 20px;
        top: 65px;
        bottom: 0;
        z-index: -1;
        left: 198px;
    }

    .recycle-items__empty {
        margin: 4em auto;
        text-align: center;
        font-size: 20px;
        color: darkslategrey;
        font-weight: bold;
    }

    .bin__controls {
        margin: 20px 0;

        .bin__controls--prefix {
            padding-right: 1em;
            font-weight: bold;
        }
    }

    #RecycleBinTable {

        > tbody {
            > tr {

                &.recycle-items__row:not(.recycle-items__row--visible) {
                    display: none;
                }

                td:first-child {
                    width: 0.1%;
                    white-space: nowrap;
                }

                &:hover {
                    background-color: #cad0d9;
                    cursor: pointer;
                }

                label {
                    cursor: pointer;
                }

                .recycle-items__icon:before {
                    content: "\f096";
                    font-family: FontAwesome;
                }

                .js-table-selectable--selected {
                    .recycle-items__icon:before {
                        content: "\f046";
                        font-family: FontAwesome;
                        color: #8ec549;
                    }
                }

                .recycle-items__item {
                    display: none;
                    visibility: hidden;
                    pointer-events: none;
                    cursor: pointer;
                }
            }
        }

        > thead {
            > tr {
                th:first-child {
                    width: 1em;
                    white-space: nowrap;
                }

                .recycle-item__sortable {
                    cursor: pointer;

                    span {
                        transition: all .5s ease;
                        float: right;

                        &.ASC {
                            transform: rotate(180deg);
                        }
                    }
                }

                label {
                    cursor: pointer;
                }

                .js-table-selectable--selected {
                    .recycle-items__icon:before {
                        content: "\f046";
                        font-family: FontAwesome;
                        color: #8ec549;
                    }
                }
            }
        }
    }
}
