
.alert-dirty-form {
    position: fixed;
    top: 0px; left: 0px; right: 0px;
    border-width: 0 0 1px 0;
    border-radius: 0;
    text-align: center;
    padding: 10px;
    > button { margin: 0 8px; }
    transition: top 300ms;
    opacity: 0.96;
}

.alert-dirty-form.hidden {
    display: block !important;
    visibility: visible !important;
    top: -100px;
}




.warning-dirty-form {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    border-width: 0 0 1px 0;
    border-radius: 0;
    text-align: center;
    padding: 10px;

    > button {
        margin: 0 8px;
    }

    transition: top 300ms;
    opacity: 0.96;
}