.nowrap {
    white-space: nowrap;
}

.table.table-dashboard {
    tr > :first-child {
        padding-left: 16px;
    }

    tr > * {
        padding: 13px 16px 13px 0;
        vertical-align: middle;
        border-top-width: 0;
        border-bottom: 1px solid #dddddd;
    }
                                                                    

    .right {
        text-align: right;
    }
}

.table.table-inbox {
    > tbody > tr {
        cursor: pointer;
        line-height: 2em;
    }

    .inbox-is-unread {
        color: #00baff;
    }

    .inbox-sent-date {
        text-align: right;
    }

    .inbox-subject {
        width: 100%;
        line-height: 1.2em;

        > a {
            color: #333;
        }
    }
}
