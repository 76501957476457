.calendar,
.calendar td,
.calendar th {
  text-align: center !important;
}

.calendar td {
  width: 14.2%;
}

.calendar-weeknumber {
  width: 0px !important;
  font-weight: bold;
  vertical-align: middle !important;
}

.calendar-holiday {
  color: orangered;
  height: 20px;
}

.calendar-day {
  cursor: pointer;
}

.calendar-day-date {
  text-align: right;
  color: black;
}

.calendar-day-status > img {
  margin-left: -28px;
}
.calendar-day-status > span {
  margin-top: 5px;
  display: inline-block;
  border: 1px solid lightgray;
  width: 20px;
  height: 10px;
}

.calendar-status-NotStarted {
  background: white;
}
.calendar-status-Started {
  background: #e4e573;
}
.calendar-status-Done {
  background: #95b300;
}
.calendar-status-NotApproved {
  background: #ff0000;
}
.calendar-day-active {
  background: LightSteelBlue;
}

/* KitchenProductionCalendarControl */

/* day names and week names center */
.KPCalendarControlTBL_WeekNr,
th.KPCalendarControlTBL_WeekDay,
.KPCalendarControlTBL_TopLeftBox {
  vertical-align: center;
  text-align: center;
}
.KPCalendarControlTBL_WeekNr > a,
th.KPCalendarControlTBL_WeekDay > a,
.KPCalendarControlTBL_TopLeftBox > a {
  display: block;
  color: black;
  text-decoration: none;
  font-weight: bold;
}
.KPCalendarControlTBL_WeekDay > a {
  padding: 4px 16px;
  margin: 0 2px;
}
.KPCalendarControlTBL_WeekNr > a {
  padding: 16px 0;
}
.KPCalendarControlTBL_TopLeftBox > a {
  margin: 2px;
  height: 20px;
  width: 20px;
}

/* top-left blue box  */
th.KPCalendarControlTBL_TopLeftBox {
  position: relative;
  min-width: 22px;
  width: 22px;
  height: 22px;
  background: #0f3166;
}

/* day cell styles */
td.KPCalendarControlTBL_WeekDay {
  width: 14.27%;
}

td.KPCalendarControlTBL_WeekDay > div {
  padding: 4px;
  height: 85px;
  overflow-y: auto;
}

td.KPCalendarControlTBL_WeekDay > div,
td.KPCalendarControlTBL_WeekDay > div * {
  cursor: pointer;
}

.KPCalendarControlTBL_Day {
    text-align: right;
    padding-right: 2px;
}

.KPCalendarControlTBL {
  width: 100%;
  border-collapse: collapse;
}

.KPCalendarControlTBL td,
.KPCalendarControlTBL th {
  border: 1px solid #0f3166;
  padding: 0;
  position: relative;
}

.KPCalendarControlTBL .is-holiday {
  color: red;
}

.KPCalendarControlTBL .is-selected {
  background-color: #b0c4de;
}

.KPCalendarControlTBL_DayMenu_Name {
  width: 33%;
  font-weight: bold;
  float: left;
  clear: left;
  white-space: nowrap;
}

.KPCalendarControlTBL_DayMenu_Value {
  width: 66%;
  max-width: 120px;
  float: right;
  clear: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
