.iolegend {
    position: relative;
    width: 160px;
    height: 50px;
    font-family: Arial;
    font-size: 11px;
}

.iolegend__swatch1 {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #95b300;
    width: 17px;
    height: 11px;
    border: 1px solid #ccc;
}

.iolegend__swatch2 {
    position: absolute;
    bottom: 0;
    left: 19px;
    background: #e4e573;
    width: 17px;
    height: 11px;
    border: 1px solid #ccc;
}

.iolegend__line1 {
    position: absolute;
    left: 9px;
    bottom: 11px;
    border: 1px solid #ccc;
    border-width: 1px 0 0 1px;
    width: 60px;
    height: 30px;
}

.iolegend__line2 {
    position: absolute;
    left: 27px;
    bottom: 11px;
    border: 1px solid #ccc;
    border-width: 1px 0 0 1px;
    width: 42px;
    height: 10px;
}

.iolegend__desc1 {
    position: absolute;
    left: 73px;
    bottom: 35px;
}

.iolegend__desc2 {
    position: absolute;
    left: 73px;
    bottom: 15px;
}
