@import "mashiecolors.less";


.blinkgreen-frames {
    0%{
        background-color: inherit;
        color: inherit;
    }
    50%{
        background-color: lighten(@ok-green,30%);
       
    }
    100%{
        background-color: inherit;
         
    }
}

@-webkit-keyframes blinkgreen {.blinkgreen-frames;}
@-moz-keyframes blinkgreen {.blinkgreen-frames;}
@-ms-keyframes blinkgreen {.blinkgreen-frames;}
@-o-keyframes blinkgreen {.blinkgreen-frames;}
@keyframes blinkgreen {.blinkgreen-frames;}


.blink-color-red {
    0% {
        border-color: inherit;
    }

    50% {
        border-color: lighten(@red,30%); 
        border-width: 4px;
    }

    100% {
        border-color: inherit;
    }
}

@keyframes blink-color-red { .blink-color-red;}

.animate-warning{
    animation : blink-color-red;
    animation-duration: 0.3s;
    animation-iteration-count: 3;
}