#unitadminpage {
    fieldset {
        margin-bottom: 15px;
    }
    #countryCodeSelectorLabel, legend{
        font-size: 18px;
        font-weight:normal;
        font-family:Arial;
    }
    .mashie-table{
        td:last-of-type{
            /*text-align:center;
            vertical-align: middle;*/
        }
    }
}
