.monthly-waste-dialog {
  > tbody,
  > tfoot,
  > thead {
    > tr {
      td {
        padding: 2px @table-cell-padding;

        &.empty {
          border: 1px solid #ddd;
          background-color: #f5f5f5;
        }

        button {
          background-color: white;
          border: 1px;
          font-weight: normal;
          font-family: Arial, Helvetica, sans-serif;
          padding: 0;

          &:enabled {
            color: #375382;
          }
        }
      }

      th {
        word-break: break-word;
      }

      th,
      td {
        @media print {
          padding: 2px;
          font-size: 10px;
        }
      }
    }
  }

  @media print {
    &__modal-body {
      padding: 5px;
    }
  }
}
