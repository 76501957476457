
.tag-selection-flex-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    height: 100%;
}

.tag-selection-flex-item {
    width: 100%;
    margin-top: 5px;
    display: flex;
}

.tag-selection-flex-grow-item {
    width: 100%;
    flex-grow: 1;
    overflow: auto;
    height: 250px;
    margin-top: 5px;
}

.tag-selection-label {
    width: 30%;
}

.tag-selection-input {
    width: 70%;
}
