.mashie-ui__dropdown{
    
    display: block;
    min-width: 0;
    cursor: pointer;
    word-wrap: break-word;
    line-height: 1em;
    white-space: normal;
    outline: 0;
    transform: rotateZ(0);
    background: #FFF;
    padding: .78571429em 2.1em .78571429em 1em;
    color: rgba(0,0,0,.87);
    box-shadow: none;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    transition: box-shadow .1s ease,width .1s ease;

    
    

    &:hover{
         border: 1px solid rgba(149, 180, 23, 1);
    }

    &.active{
        border: 1px solid rgba(149, 180, 23, 1);
        z-index:10;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    input{
        display:none !important;
    }

    .default{
        color:darkgrey;
        display:inline-block;
        transition:none;
    }

    .menu{
        border-color: rgba(149, 180, 23, 1);
        overflow-x: hidden;
        overflow-y: auto;
        border-top-width: 0 !important;
        outline: 0;
        margin: 0 -1px;
        min-width: calc(~'100% + 2px');
        width: calc(~'100% + 2px');
        border-radius: 0 0 .28571429rem;
        left: 0;
        cursor: auto;
        position: absolute;
        top:100%;
        padding:0;
        background: #FFF;
        backface-visibility: hidden;
        

        border: 1px solid rgba(149, 180, 23, 1);

        opacity:1;
        transform: scaleY(1);
        transition: transform .5s ease, opacity .5s linear;
        transform-origin: top left;
        transform-style: preserve-3D;

        
        &.menu-inactive{
            opacity: 0;
            transform: scaleY(0);
        }

    }


   

    .item{
         border-top: 1px solid #FAFAFA;
        padding: 0.8em 1.1em;
        &:hover{
             background:rgba(149, 180, 23, 0.5);
        }
    }
    

}