.dashboard-account-info {
    padding: 15px;
    max-width: 80em;
    margin: 0;

    > div {
        margin-bottom: 12px;
    }
    > dl > dt {
        float: left;
        clear: left;
    }
    > dl > dd {
        float: right;
        clear: right;
    }
    > .updated-meals-recipe-count {
        background: url('../../../../Images/attention.png') center left no-repeat;
        padding-left: 40px;
        height: 40px;
    }
}