#holidayEdit {
    background-color: white;
    padding-top: 15px;
    padding-left: 10px;
    .mashie-warningmessages {

        .mashie-warning {
            color: #ff3131;
            margin: 10px 0;
        }
    }




    .mashie-warning {
        color: #ff3131;
    }


    .mashieForm {
       
        margin-bottom: 35px;
        float: left;
        min-width: 160px;
        width: 10vw;

        input,select,button {
            margin-bottom: 10px;

        }
    }

    /*.holidayTalbeWrapper {
        .mashie-table {
            min-width: 400px;
            width: 30vw;
            cursor: default;

            tbody {
                tr {
                    &:hover {
                        .mashiebutton-remove {
                            &:disabled(not) {
                                color: red;
                            }
                        }
                    }
                }
            }


            td:last-of-type {
                text-align: center;
            }
        }
    }*/
}
