
.recipeLabelContainer {
    width: 100%;

    .orginalrecipelabel {
        width: 100%;
        font-size: 12px;

        .orginalrecipelabelwrapper {
            padding: 16px 0 0 24px;
            border-radius: 2px;
            width: 100%;
            height: 40px;
        }

        h3 {
            font-size: 12px;
            font-weight: bold;
            margin: 0 0 10px 0;
        }
    }

    .orginalrecipelabelwrapper--label {
        padding: 10px 10px 10px 25px;
        font-weight: bold !important;
    }

    .orginalrecipelabelwrapper {
        background-color: #eeeeee;
    }


    .recipelabel-wysiwyg-container {
        padding: 10px 10px 15px 45px;
    }

    .editrecipelabel {
        width: 100%;
        margin: 0 0 20px 0;

        .editrecipelabelwrapper {
            border-radius: 2px;
            height: 35px;
            background-color: #eeeeee;
            margin-bottom: 20px;
        }

        label {
            color: #000;
            font-weight: bold;
        }

        .editrecipelabelwrapper--label {
            padding: 10px 10px 10px 25px;
        }
    }

    .delete-visable {
        width: 245px;
    }

    .active {
        background: #B0C4DE;
        color: black;
    }

    .list-group-item {
        width: 100%;
        padding: 0;
        height: 32px;
        margin-bottom: -2px;
    }

    .list-group-item:first-child {
        border-radius: 2px !important;
    }

    .list-group-item:last-child {
        border-radius: 2px !important;
    }

    .list-group {
        padding: 0 0 0 10px;
    }
}

.editrecipelabel-container {
    width: 100%;
    float: left;
    margin: 0 0 20px 0;
}

.custom-ingredient-default-allergens-view--label {
    font-weight: bold !important;
    margin: 0 0 10px 0;
}

.orginalrecipelabelwrapper-container {
    width: 100%;
    float: left;
}

.recipelabel-orginaltext-wrapper {
    padding: 10px 10px 10px 5px;
    margin: 10px 0 10px 25px;
    width: 80%;

}

.recipelabel-wysiwyg {
    padding: 5px 10px 0 0;
    width: 80%;
    float: left;

    h3 {
        font-size: 12px;
        font-weight: bold;
        margin: 0 0 10px 0;
    }
}

.validator::-webkit-input-placeholder {
    color: #EC1111;
}


#recipelabel-information-locked {
    width: 100%;
}

#recipelabel-information-unlocked {
    width: 100%;
}

.recipelabel-button {
    height: 35px;
    background: #E9E9E9;
    min-width: 90px;
    color: #000;
    border-radius: 3px;
    border: none;
}


.recipelabel-icon-hover:hover {
    color: #fff !important;
}

.recipelabel-template-checkbox {
    top: 4px !important;
    margin: 5px 0 0 15% !important;
}

.recipelabel-radio-button {
    margin: 10px 14px 10px 14px;
    width: 20px;
}

.recipelabel-label-standard {
    font-weight: bold !important;
    float: right;
    padding: 5px 8% 0 0;
    margin: 0;
}
.recipelabel-label-saved{
    display: none;
    color: #95b417 !important;
    margin: 5px 0 0 10px;
}

.recipelabel-label-not-saved{
    display: none;
    color: #dd0000 !important;
    margin: 5px 0 0 10px;
}

.recipelabel-delete-button {
    margin: 0 0 0 -2px;
    background: rgb(231, 235, 243);
    cursor: pointer;
}

.recipelabel-new-button {
    margin: 0 0 0 30px;
    cursor: pointer;
}

.recipelabel-save-button {
    cursor: pointer;
    margin: 0 0 5px -2px;
}

.recipelabel-label {
    line-height: 30px;
    margin: 0 0 5px;
}

.recipelabel-padding {
    padding: 5px;
    margin: 0;
}

.recipelabel-input {
    margin: 0 5px 0 5px;
    width: 200px;
}

.recipelabel-modified-info {
    background: #EFEFEF;
    color: #000;
    border-radius: 3px;
    height: 25px;
    line-height: 15px;
}

.recipelabel-template-head {
    border: 1px solid #dddddd;
    padding: 0 5px 0 0;
    border-radius: 2px;
    margin: 0 5px 0 15px;
    width: 100%;
}

.recipelabel-list-group-item {
    width: 470px;
}

.recipelabel-custom-template {
    margin: 0 0 0 15px;
    width: 100%;
}

.recipelabel-templete-continer {
    max-width: 400px;
    width: 100%;
    float: left;
    margin: 0 0 50px 15px;
}

.recipelabel-template-checkbox-default {
    margin: 0 5px 0 6px !important;
}

.recipelabel-label-header {
    font-weight: bold !important;
    width: 67%;
    padding: 5px;
    border-right: 1px solid #eeeeee;
}

.recipelabel-list-group {
    width: 100%;
    margin: 0 0 0 15px;
    padding: 0;
    position: relative;
    border: 1px solid #dddddd;
}

.recipelabel-icon-container {
    margin: 0 0 0 0;
    float: left;
    padding: 0 0 0 5px;

    .recipelabel-icon-new {
        cursor: pointer;
        color: #FFF;
        font-size: 16px;
    }
}

.templeterecipewrapper {
    border-radius: 2px;
    width: 100%;
    height: 40px;
    margin: 20px 0 10px 0;
    background: #F6F6F6;

    label {
        padding: 5px 0 0 15px;
        color: #000;
        font-weight: 500 !important;
    }
}

.recipelabel-float--left {
    float: left;
}

#recipelabel-delete-modal {
    top: 35%;
    left: 20%;
    width: 400px;
    height: 350px;


    .modal-body {
        padding: 20px 20px 0 20px;
    }

    button {
        border: none;
    }
}

#recipelabel-warning-modal {
    top: 35%;
    left: 20%;
    width: 400px;
    height: 350px;


    .modal-body {
        padding: 20px 20px 0 20px;
    }

    button {
        border: none;
    }
}

.recipelabel-float--right {
    float: right;
}

.recipelabel-util-button {
    font-size: 15px;
    padding: 10px 25px 10px 22px;
    color: #B0B8C7;
    cursor: pointer;
}

.recipelabel-util-button:hover {
    color: #375382;
}

.recipelabel-new-button-icon {
    position: absolute;
    bottom: 3px;
    left: 246px;
    width: 9px;
    font-size: 14px;
    color: #375382;
    height: 0;
    padding: 10px 25px 20px 13px;
}

.recipelabel-modified {
    height: 25px;
    line-height: 17px;
    margin-left: 315px;
}

.recipelabel-templete-label {
    padding: 0 0 0 15px;
    width: 66%;
    height: 30px;
    text-align: left;
    border: none !important;
    margin: 0;
    border-right: 1px solid #eee !important;
    cursor: pointer;
    outline: none !important;
}

.recipelabel-new-input {
    padding: 0 0 0 15px;
    height: 29px;
    outline: none;
    text-align: left;
    background-color: #fff;
    color: #000;
}


.recipelabel-new-input::-webkit-input-placeholder {
    color: #50579d;
}


#custom-ingredient-default-allergens-view {
    width: 100%;
    padding: 0 10px 45px 35px;
}

#custom-ingredient-allergens-view {
    padding: 10px 10px 45px 45px;
}
