@import '../mashiecolors.less';

.dashboard .grid .gs-w.waste-panel {
    .panel-body {
        overflow-x: hidden;
        padding: 1em;

        .waste {
            .waste-type-switcher {
                margin-bottom: 30px;
                .nav-tabs > li {
                    width: 50%;
                }
                .nav-tabs > li:last-child a {
                    margin-right: 0;
                }
            }

            .waste__calendar {
                .waste__update-notice--hide {
                    transform: translate(0, -100%);
                    height: 0;
                    line-height: 0;
                    margin-bottom: 1em;
                }

                .waste__update-container {
                    overflow: hidden;
                    position: relative;

                    .waste__update-notice {
                        overflow-y: hidden;
                        height: 100%;
                        padding: 0.75rem 1.25rem;
                        margin-bottom: 1em;
                        border: 1px solid;
                        border-radius: 0.25rem;
                        transition: all 0.5s;

                        &.waste__update-notice--success {
                            background-color: #dff0d8;
                            border-color: #d0e9c6;
                            color: #3c763d;
                        }

                        &.waste__update-notice--error {
                            background-color: #f2dede;
                            border-color: #ebccd1;
                            color: #a94442;
                        }

                        .waste__update-notice--show {
                            transform: translate(0, 0%);
                        }
                    }
                }

                .calendar__container {
                    display: flex;
                    justify-content: space-between;
                    background-color: #0f3166;
                    padding: 0.15em;
                    border-radius: 4px;

                    .calendar__arrow {
                        flex: 0 1 3em;

                        button {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .calendar__input {
                        background-color: white;
                        width: 15em;
                        padding: 0.25em 1em;
                        display: flex;
                        text-align: center;
                        font-weight: bold;
                        align-items: center;

                        button {
                            width: auto !important;
                            flex: 1 1 auto;
                            background-color: white;
                            border: 1px solid #444;
                            border-radius: 4px;
                            color: #444;

                            &:hover {
                                background-color: #ccc;
                            }
                        }

                        span {
                            flex: 5 1 auto;
                        }
                    }
                }
            }

            .waste__loader--spinner {
                color: green;
                font-size: 5em;
                display: inline-block;
                position: absolute;
                width: calc(~'100% - .5em');
                top: 40%;
                text-align: center;
            }

            .waste__fields {
                margin-bottom: 2em;
                display: inline-block;
                width: 100%;

                .error_input {
                    .waste__field {
                        .field__input {
                            border: 2px solid #a94442;
                        }
                    }
                }

                .waste__field {
                    display: inline-flex;
                    width: 60%;
                    margin-bottom: 1em;

                    .field__value {
                        width: 50%;
                        padding-top: 1px;
                    }

                    .field__input {
                        width: 30%;
                        padding-top: 1px;

                        &.error_input {
                            border: 2px solid #a94442;
                        }
                    }

                    .field__unit {
                        padding-top: 1px;
                    }

                    input {
                        border: 2px #888 solid;
                        width: 7em;
                        text-align: right;
                        padding-right: 0.25em;
                    }
                }

                .waste__served-portions {
                    text-align: right;
                    padding-top: 1px;
                    display: inline-flex;
                    width: 40%;

                    a {
                        width: 100%;
                    }
                }
            }

            .waste__information {
                margin-bottom: 1em;

                .information__labels {
                    display: flex;
                    margin-bottom: 1em;

                    .information__label {
                        display: flex;

                        .label__header {
                            margin-right: 1em;
                        }

                        .label__value {
                            margin-right: 2em;

                            &.label__value--exceeded {
                                color: @warninig-red;
                            }

                            &.label__value--under {
                                color: @mashie-green;
                            }
                        }
                    }
                }

                .information__description {
                    display: flex;

                    .description__label {
                        margin-right: 1em;
                    }

                    .description__textarea {
                        flex: 1;

                        textarea {
                            resize: vertical;
                        }
                    }
                }
            }

            .waste__controls {
                display: flex;
                justify-content: space-between;

                button:disabled {
                    opacity: 0.65;
                    cursor: not-allowed;
                }
            }
        }
    }
}
